import _ from "lodash";

export function fileSizeCheck(size, limit) {
  if (size > limit) {
    return false;
  }
  return true;
}

export function fileTypeCheck(fileName) {
  const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
  if (!allowedExtensions.test(fileName)) {
    return false;
  }
  return true;
}
export function fileUploadTypeCheck(fileName) {
  const allowedExtensions =
    /(\.pdf|\.doc|\.docx|\.xls|\.xlsx|\.ppt|.pptx|\.txt|\.csv)$/i;
  if (!allowedExtensions.test(fileName)) {
    return false;
  }
  return true;
}

export function pdfTypeCheck(fileName) {
  const allowedExtensions = /(\.pdf)$/i;
  if (!allowedExtensions.test(fileName)) {
    return false;
  }
  return true;
}

export function csvTypeCheck(fileName) {
  const allowedExtensions = /(\.csv)$/i;
  if (!allowedExtensions.test(fileName)) {
    return false;
  }
  return true;
}

export function isNotEmpty(str) {
  const pattern = /\S+/;
  return pattern.test(str); // returns a boolean
}

export function isEmpty(str) {
  return !isNotEmpty(str);
}

export function isEmailAddress(str) {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(str); // returns a boolean
}

export function isEmptyObject(obj) {
  for (let prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return true;
}

export const isNumeric = (v) => {
  return !isNaN(parseFloat(v)) && isFinite(v);
};

export const isValidURL = (v) => {
  let validURL = null;
  try {
    validURL = new URL(v);
  } catch {
    return false;
  }
  const urlRegex = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  if (validURL?.host) {
    return urlRegex.test(validURL.host.toLowerCase().replace("www.", ""));
  }
  return urlRegex.test(v);
};

export function isObjectsEqual(obj1, obj2) {
  return _.isEqual(obj1, obj2);
}

export const isValidPostalCode = (zipCode) => {
  const zipCodeRegexUS = /^[0-9]{5}(?:-[0-9]{4})?$/;
  return zipCodeRegexUS.test(zipCode);
};

export function isBothArrayEqual(x, y) {
  const data = _.differenceWith(x, y, _.isEqual);
  return _.isEmpty(data);
}

export function isValidatePhoneNumber(phoneNumber) {
  const phoneRegex = /^\d{11}$/;
  return phoneRegex.test(phoneNumber);
}

export const isSlugValid = (slug) => {
  const slugRefEx = /^[a-z]+(-?[a-z0-9]+)*$/;
  return slugRefEx.test(slug);
};
