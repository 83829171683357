/* eslint-disable max-lines */
import { tggClient, tggTenantClient } from "@services/AxiosConfig";
import {
  AgencySettingsApi,
  ClientSettingsApi,
} from "@tgg_accounting/hub-node-api";
import { ReportTemplateDisplayModel } from "@tgg_accounting/hub-node-api/dist/models";
import { AxiosResponse } from "axios";
import { QueryFunctionContext } from "react-query";

// Input Types
interface GetReportTemplateInput {
  queryKey: [string, { clientId: string; skip?: number; take?: number }];
}

interface PostReportTemplateInput {
  name: string;
  version: string;
}

interface UpdateReportTemplateInput {
  name: string;
  version: string;
}

interface GetReportModulesInput {
  queryKey: [
    string,
    {
      clientId: string;
      reportTemplateId: string;
      mode: string;
      year: number;
      month: number;
    }
  ];
}

interface AddReportModuleInput {
  reportModuleId: string | string[] | undefined;
}

interface GetCoverPagePreviewInput {
  queryKey: [string, { clientId: string; year: number; month: number }];
}

interface GetClientSnapShotPreviewInput {
  queryKey: [string, { clientId: string; year: number; month: number }];
}

interface GetClientPreConditionsInput {
  queryKey: [string, { clientId: string }];
}

interface GetTripleBottomLinePreviewInput {
  queryKey: [
    string,
    { clientId: string; year: number; month: number; reportType: string }
  ];
}

interface UpdateTripleBottomLineDescriptionInput {
  description: string;
}

interface GetIncomeStatementPreviewInput {
  queryKey: [string, { clientId: string; year: number; month: number }];
}

interface GetBalanceSheetPreviewInput {
  queryKey: [string, { clientId: string; year: number; month: number }];
}

interface GetCashFlowStatementPreviewInput {
  queryKey: [string, { clientId: string; year: number; month: number }];
}

interface GetARAPPreviewInput {
  queryKey: [
    string,
    {
      clientId: string;
      year: number;
      month: number;
      type: string;
      mode: string;
    }
  ];
}

interface PostWidgetSelectionModuleInput {
  name: string;
  description: string;
  id: string;
  reportType: string;
  autoCommentary: string;
  settings: string;
}

interface GetWidgetSelectionModuleConfigurationInput {
  queryKey: [
    string,
    { clientId: string; reportTemplateId: string; moduleId: string }
  ];
}

interface PostMonthlyReportNoteInput {
  note: string;
  noteType: string;
}

interface GetMonthlyReportNotesInput {
  queryKey: [
    string,
    { clientId: string; year: string; month: string; type: string }
  ];
}

interface GetAISTableOfNoteInput {
  queryKey: [
    string,
    { clientId: string; year: string; month: string; noteId: string }
  ];
}

interface GetAllAISTableOfNotesInput {
  queryKey: [
    string,
    { clientId: string; year: string; month: string; data: any }
  ];
}

interface GetCustomerVendorTableOfNoteInput {
  queryKey: [
    string,
    { clientId: string; year: string; month: string; noteId: string }
  ];
}

interface GetFinancialPackageDetailsInput {
  queryKey: [string, { clientId: string; financialPackageId: string }];
}

interface GetDFMConfigInput {
  queryKey: [string, { clientId: string; reportTemplateId: string }];
}

interface Get13MonthReportInput {
  queryKey: [
    string,
    {
      clientId: string;
      year: number;
      month: number;
      statementType: string;
      mode: string;
      id?: string;
    }
  ];
}

interface GetCISReportInput {
  queryKey: [
    string,
    {
      clientId: string;
      year: number;
      month: number;
      mode: string;
      id: string;
      customModuleId?: string;
    }
  ];
}

interface GetCBSReportInput {
  queryKey: [
    string,
    {
      clientId: string;
      year: number;
      month: number;
      mode: string;
      id: string;
      customModuleId?: string;
    }
  ];
}

interface GetCFSReportInput {
  queryKey: [
    string,
    {
      clientId: string;
      year: number;
      month: number;
      mode: string;
      id: string;
      customModuleId?: string;
    }
  ];
}

interface GetComparableReportInput {
  queryKey: [
    string,
    {
      clientId: string;
      year: number;
      month: number;
      type: string;
      statementType: string;
      mode: string;
      id: string;
    }
  ];
}

interface GetCustomTrailingStatementInput {
  queryKey: [
    string,
    {
      clientId: string;
      year: number;
      month: number;
      statementType: string;
      trailValue: string;
      type: string;
      mode: string;
      id?: string;
    }
  ];
}

interface PostCustomReportTemplateInput {
  name: string;
  description: string;
}

interface UpdateCustomReportTemplateInput {
  name: string;
  description: string;
}

interface GetMonthlyReportModulesConfigurationInput {
  queryKey: [
    string,
    {
      clientId: string;
      year: number;
      month: number;
      reportModuleConfigId: string;
    }
  ];
}

interface PostMonthlyReportModulesConfigurationInput {
  sections: {
    id: string;
    title: string;
    displayOrder: number;
    pages: string;
    landscapePages: string[];
  }[];
}

interface GetKPIFinancialPreviewInput {
  queryKey: [
    string,
    {
      clientId: string;
      kpiId: string;
      year: number;
      month: number;
      kpiType: string;
    }
  ];
}

interface GetIncomeStatementClassificationInput {
  queryKey: [
    string,
    { clientId: string; year: number; month: number; type: string }
  ];
}

interface GetTableOfContentInput {
  queryKey: [
    string,
    {
      clientId: string;
      reportTemplateId: string;
      year: number;
      month: number;
      notesNoOfPages: number;
    }
  ];
}

interface AddCustomTableData {
  period: null;
  rowCount: any;
  mode: null;
  reportModuleConfigId: null;
  clientReportModuleConfigId: null;
  noteId: null;
  columns: any;
}

interface PostDataTableSettingsInput {
  isReportTemplate: any;
  title: any;
  input: any[];
  intervalType: any;
  trailingPeriods: any;
  descriptionMonthly: any;
  id: any;
  year: any;
  month: any;
}

class ReportsServices {
  clientSettingsAPI = new ClientSettingsApi(
    undefined,
    process.env.NEXT_PUBLIC_TGG_API_BASE_URI,
    tggClient
  );

  agencySettingsAPI = new AgencySettingsApi(
    undefined,
    process.env.NEXT_PUBLIC_TGG_API_BASE_URI,
    tggClient
  );

  getReportTemplate = async ({
    queryKey,
  }: QueryFunctionContext<
    GetReportTemplateInput["queryKey"]
  >): Promise<AxiosResponse<ReportTemplateDisplayModel> | null> => {
    const [_key, { clientId, skip = 0, take = 10 }] = queryKey;
    if (clientId) {
      return await tggClient.get(`/clients/${clientId}/report-templates`, {
        params: { skip, take },
      });
    }
    console.warn("clientId not provided, ReportsServices.getReportTemplate");
    return null;
  };

  postReportTemplate = async (
    clientId: string,
    data: PostReportTemplateInput
  ) => {
    if (clientId) {
      return await tggClient.post(
        `/clients/${clientId}/report-templates`,
        data
      );
    }
    console.warn("clientId not provided, ReportsServices.postReportTemplate");
    return null;
  };

  getFinancialPackages = async ({
    queryKey,
  }: QueryFunctionContext<
    [
      string,
      {
        clientId: string;
        pagination: boolean;
        skip: number;
        take: number;
        status: string;
        year?: string;
      }
    ]
  >) => {
    const [_key, { clientId, year, pagination, skip, take, status }] = queryKey;
    if (clientId) {
      return await tggClient.get(`/clients/${clientId}/financial-packages`, {
        params: { year, pagination, status, skip, take },
      });
    }
    console.warn("clientId not provided, ReportsServices.getFinancialPackages");
    return null;
  };

  postFinancialPackage = async (
    clientId: string,
    reportTemplateId: string,
    year: string | undefined,
    month: number | undefined,
    refreshProfitRecommendation: boolean | undefined
  ) => {
    if (clientId && reportTemplateId && year && month) {
      return await tggClient.post(
        `/clients/${clientId}/report-templates/${reportTemplateId}/${year}/${month}/financial-packages`,
        refreshProfitRecommendation
          ? { refreshProfitRecommendation }
          : undefined
      );
    }
    console.warn(
      "clientId, reportTemplateId, year, month not provided, ReportsServices.postFinancialPackage"
    );
    return null;
  };

  updateReportTemplate = async (
    clientId: string,
    reportTemplateId: string,
    data: UpdateReportTemplateInput
  ) => {
    if (clientId && reportTemplateId) {
      return await tggClient.patch(
        `/clients/${clientId}/report-templates/${reportTemplateId}`,
        data
      );
    }
    console.warn(
      "clientId, reportTemplateId not provided, ReportsServices.updateReportTemplate"
    );
    return null;
  };

  getReportModules = async ({
    queryKey,
  }: QueryFunctionContext<
    GetReportModulesInput["queryKey"]
  >): Promise<AxiosResponse<any> | null> => {
    const [_key, { clientId, reportTemplateId, mode, year, month }] = queryKey;
    if (clientId && reportTemplateId && mode) {
      return await tggClient.get(
        `/clients/${clientId}/report-templates/${reportTemplateId}/modules`,
        { params: { mode, year, month } }
      );
    }
    console.warn(
      "clientId, reportTemplateId or mode is not provided, ReportsServices.getReportModules"
    );
    return null;
  };

  addReportModule = async (
    clientId: string,
    reportTemplateId: string | string[] | undefined,
    data: AddReportModuleInput
  ) => {
    if (clientId && reportTemplateId && data) {
      return await tggClient.post(
        `/clients/${clientId}/report-templates/${reportTemplateId}/modules`,
        data
      );
    }
    console.warn(
      "clientId or reportTemplateId not provided, ReportsServices.addReportModule"
    );
    return null;
  };

  removeReportModule = async (
    clientId: string,
    reportTemplateId: string,
    reportModuleId: string
  ) => {
    if (clientId && reportTemplateId && reportModuleId) {
      return await tggClient.delete(
        `/clients/${clientId}/report-templates/${reportTemplateId}/modules/${reportModuleId}`
      );
    }
    console.warn(
      "clientId or reportTemplateId or reportModuleId not provided, ReportsServices.removeReportModule"
    );
    return null;
  };

  getCoverPagePreview = async ({ queryKey }: GetCoverPagePreviewInput) => {
    const [_key, { clientId, year, month }] = queryKey;
    if (clientId && year && month) {
      return await tggTenantClient.get(
        `/clients/${clientId}/years/${year}/month/${month}/cover-page`
      );
    }
    console.warn(
      "clientId or year or month not provided, ReportsServices.getCoverPagePreview"
    );
    return null;
  };

  getClientSnapShotPreview = async ({
    queryKey,
  }: GetClientSnapShotPreviewInput) => {
    const [_key, { clientId, year, month }] = queryKey;
    if (clientId && year && month) {
      return await tggClient.get(
        `/clients/${clientId}/years/${year}/month/${month}/client-snapshot`
      );
    }
    console.warn(
      "clientId or year or month not provided, ReportsServices.getClientSnapShotPreview"
    );
    return null;
  };

  getClientPreConditions = async ({
    queryKey,
  }: GetClientPreConditionsInput) => {
    const [_key, { clientId }] = queryKey;
    if (clientId) {
      return await tggClient.get(`/clients/${clientId}/reports/pre-conditions`);
    }
    console.warn(
      "clientId not provided, ReportsServices.getClientPreConditions"
    );
    return null;
  };

  getClientPreConditionsDirect = async (clientId: string) => {
    if (clientId) {
      return await tggClient.get(`/clients/${clientId}/reports/pre-conditions`);
    }
    console.warn(
      "clientId not provided, ReportsServices.getClientPreConditionsDirect"
    );
    return null;
  };

  getTripleBottomLinePreview = async ({
    queryKey,
  }: GetTripleBottomLinePreviewInput) => {
    const [_key, { clientId, year, month, reportType }] = queryKey;
    if (clientId && year && month) {
      return await tggClient.get(
        `/clients/${clientId}/reports/${year}/${month}/tbl`,
        { params: { reportType } }
      );
    }
    console.warn(
      "clientId or year or month or reportType not provided, ReportsServices.getTripleBottomLinePreview"
    );
    return null;
  };

  updateTripleBottomLineDescription = async (
    clientId: string,
    year: string,
    month: string,
    reportType: string,
    data: UpdateTripleBottomLineDescriptionInput
  ) => {
    if (clientId && year && month && reportType && data) {
      return await tggClient.post(
        `/clients/${clientId}/reports/${year}/${month}/tbl-description`,
        data,
        { params: { reportType } }
      );
    }
    console.warn(
      "clientId or year or month or reportType or data not provided, ReportsServices.updateTripleBottomLineDescription"
    );
    return null;
  };

  getIncomeStatementPreview = async ({
    queryKey,
  }: GetIncomeStatementPreviewInput) => {
    const [_key, { clientId, year, month }] = queryKey;
    if (clientId && year && month) {
      return await tggClient.get(
        `/clients/${clientId}/reports/${year}/${month}/income-statement`
      );
    }
    console.warn(
      "clientId or year or month not provided, ReportsServices.getIncomeStatementPreview"
    );
    return null;
  };

  getBalanceSheetPreview = async ({
    queryKey,
  }: GetBalanceSheetPreviewInput) => {
    const [_key, { clientId, year, month }] = queryKey;
    if (clientId && year && month) {
      return await tggClient.get(
        `/clients/${clientId}/reports/${year}/${month}/balance-sheet`
      );
    }
    console.warn(
      "clientId or year or month not provided, ReportsServices.getBalanceSheetPreview"
    );
    return null;
  };

  getCashFlowStatementPreview = async ({
    queryKey,
  }: GetCashFlowStatementPreviewInput) => {
    const [_key, { clientId, year, month }] = queryKey;
    if (clientId && year && month) {
      return await tggClient.get(
        `/clients/${clientId}/reports/${year}/${month}/cash-flow-statement`
      );
    }
    console.warn(
      "clientId or year or month not provided, ReportsServices.getCashFlowStatementPreview"
    );
    return null;
  };

  getARAPPreview = async ({ queryKey }: GetARAPPreviewInput) => {
    const [_key, { clientId, year, month, type, mode }] = queryKey;
    if (clientId && year && month && type && mode) {
      return await tggClient.get(
        `/clients/${clientId}/reports/${year}/${month}/ar-ap`,
        { params: { type, mode } }
      );
    }
    console.warn(
      "clientId or year or month or type or mode not provided, ReportsServices.getARAPPerview"
    );
    return null;
  };

  postWidgetSelectionModule = async (
    clientId: string,
    reportTemplateId: string | string[] | undefined,
    moduleId: string | string[] | undefined,
    data: PostWidgetSelectionModuleInput[]
  ) => {
    if (clientId && reportTemplateId && moduleId && data) {
      return await tggClient.post(
        `/clients/${clientId}/report-templates/${reportTemplateId}/modules/${moduleId}`,
        data
      );
    }
    console.warn(
      "clientId or reportTemplateId or moduleId or data not provided, ReportsServices.postWidgetSelectionModule"
    );
    return null;
  };

  getWidgetSelectionModuleConfiguration = async ({
    queryKey,
  }: GetWidgetSelectionModuleConfigurationInput) => {
    const [_key, { clientId, reportTemplateId, moduleId }] = queryKey;
    if (clientId && reportTemplateId && moduleId) {
      return await tggClient.get(
        `/clients/${clientId}/report-templates/${reportTemplateId}/modules/${moduleId}`
      );
    }
    console.warn(
      "clientId or reportTemplateId or moduleId not provided, ReportsServices.getWidgetSelectionModuleConfiguration"
    );
    return null;
  };

  postMonthlyReportNote = async (
    clientId: string,
    year: string,
    month: string,
    type: string,
    data: PostMonthlyReportNoteInput
  ) => {
    if (clientId && year && month && type && data) {
      return await tggClient.post(
        `/clients/${clientId}/reports/${year}/${month}/notes`,
        data,
        { params: { type } }
      );
    }
    console.warn(
      "clientId or      year or month or type or data not provided, ReportsServices.postMonthlyReportNote"
    );
    return null;
  };

  getMonthlyReportNotes = async ({ queryKey }: GetMonthlyReportNotesInput) => {
    const [_key, { clientId, year, month, type }] = queryKey;
    if (clientId && year && month && type) {
      return await tggClient.get(
        `/clients/${clientId}/reports/${year}/${month}/notes`,
        { params: { type } }
      );
    }
    console.warn(
      "clientId or year or month or type not provided, ReportsServices.getMonthlyReportNotes"
    );
    return null;
  };

  getAISTableOfNote = async ({ queryKey }: GetAISTableOfNoteInput) => {
    const [_key, { clientId, year, month, noteId }] = queryKey;
    if (clientId && year && month && noteId) {
      return await tggClient.get(
        `/clients/${clientId}/reports/${year}/${month}/notes/${noteId}/ais-table`
      );
    }
    console.warn(
      "clientId or year or month or noteId not provided, ReportsServices.getAISTableOfNote"
    );
    return null;
  };

  getAllAISTableOfNotes = async ({ queryKey }: GetAllAISTableOfNotesInput) => {
    const [_key, { clientId, year, month, data }] = queryKey;
    if (clientId && year && month && !!data) {
      return await tggClient.post(
        `/clients/${clientId}/reports/year/${year}/month/${month}/ais-table`,
        data
      );
    }
    console.warn(
      "clientId or year or month or data not provided, ReportsServices.getAllAISTableOfNotes"
    );
    return null;
  };

  getCustomerVendorTableOfNote = async ({
    queryKey,
  }: GetCustomerVendorTableOfNoteInput) => {
    const [_key, { clientId, year, month, noteId }] = queryKey;
    if (clientId && year && month && noteId) {
      return await tggClient.get(
        `/clients/${clientId}/reports/year/${year}/month/${month}/notes/${noteId}/customer-vendor-table`
      );
    }
    console.warn(
      "clientId or year or month or noteId not provided, ReportsServices.getCustomerVendorTableOfNote"
    );
    return null;
  };

  getFinancialPackageDetails = async ({
    queryKey,
  }: GetFinancialPackageDetailsInput) => {
    const [_key, { clientId, financialPackageId }] = queryKey;
    if (clientId && financialPackageId) {
      return await tggClient.get(
        `/clients/${clientId}/financial-package/${financialPackageId}`
      );
    }
    console.warn(
      "clientId or financialPackageId not provided, ReportsServices.getFinancialPackageDetails"
    );
    return null;
  };

  postDFMConfig = async (
    clientId: string,
    reportTemplateId: string,
    data: string
  ) => {
    if (clientId && reportTemplateId && data) {
      return await tggClient.post(
        `/clients/${clientId}/report-templates/${reportTemplateId}/dfm-config`,
        data
      );
    }
    console.warn(
      "clientId or reportTemplateId or data not provided, ReportsServices.postDFMConfig"
    );
    return null;
  };

  getDFMConfig = async ({ queryKey }: GetDFMConfigInput) => {
    const [_key, { clientId, reportTemplateId }] = queryKey;
    if (clientId && reportTemplateId) {
      return await tggClient.get(
        `/clients/${clientId}/report-templates/${reportTemplateId}/dfm-config`
      );
    }
    console.warn(
      "clientId or reportTemplateId not provided, ReportsServices.getDFMConfig"
    );
    return null;
  };

  get13MonthReport = async ({ queryKey }: Get13MonthReportInput) => {
    const [_key, { clientId, year, month, statementType, mode, id = null }] =
      queryKey;
    if (clientId && year && month && statementType && mode) {
      return await tggClient.get(
        `/clients/${clientId}/reports/${year}/${month}/13-month-statements`,
        { params: { statementType, mode, id } }
      );
    }
    console.warn(
      "clientId or year or month or statementType or mode not provided, ReportsServices.get13MonthReport"
    );
    return null;
  };

  getCISReport = async ({ queryKey }: GetCISReportInput) => {
    const [_key, { clientId, year, month, mode, id, customModuleId }] =
      queryKey;
    if (clientId && year && month && mode && id) {
      return await tggClient.get(
        `/clients/${clientId}/reports/${year}/${month}/cis`,
        { params: { mode, id, customModuleId } }
      );
    }
    console.warn(
      "clientId or year or month or mode or id not provided, ReportsServices.getCISReport"
    );
    return null;
  };

  getCBSReport = async ({ queryKey }: GetCBSReportInput) => {
    const [_key, { clientId, year, month, mode, id, customModuleId }] =
      queryKey;
    if (clientId && year && month && mode && id) {
      return await tggClient.get(
        `/clients/${clientId}/reports/year/${year}/month/${month}/cbs`,
        { params: { mode, id, customModuleId } }
      );
    }
    console.warn(
      "clientId or year or month or mode or id not provided, ReportsServices.getCBSReport"
    );
    return null;
  };

  getCFSReport = async ({ queryKey }: GetCFSReportInput) => {
    const [_key, { clientId, year, month, mode, id, customModuleId }] =
      queryKey;
    if (clientId && year && month && mode && id) {
      return await tggClient.get(
        `/clients/${clientId}/reports/year/${year}/month/${month}/ccf`,
        { params: { mode, id, customModuleId } }
      );
    }
    console.warn(
      "clientId or year or month or mode or id not provided, ReportsServices.getCFSReport"
    );
    return null;
  };

  getComparableReport = async ({ queryKey }: GetComparableReportInput) => {
    const [_key, { clientId, year, month, type, statementType, mode, id }] =
      queryKey;
    if (clientId && year && month && type && statementType && mode) {
      return await tggClient.get(
        `/clients/${clientId}/reports/${year}/${month}/comparable-Statement`,
        { params: { type, statementType, mode, id } }
      );
    }
    console.warn(
      "clientId or year or month or type or statementType or mode not provided, ReportsServices.getComparableReport"
    );
    return null;
  };

  getCustomTrailingStatement = async ({
    queryKey,
  }: GetCustomTrailingStatementInput) => {
    const [
      _key,
      {
        clientId,
        year,
        month,
        statementType,
        trailValue,
        type,
        mode,
        id = null,
      },
    ] = queryKey;
    if (
      clientId &&
      year &&
      month &&
      statementType &&
      trailValue &&
      type &&
      mode
    ) {
      return await tggClient.get(
        `/clients/${clientId}/reports/${year}/${month}/custom-trail/${trailValue}`,
        { params: { statementType, trailType: type, mode, id } }
      );
    }
    console.warn(
      "clientId or year or month or statementType or trailValue or type or mode not provided, ReportsServices.getCustomTrailingStatement "
    );
    return null;
  };

  postCustomReportTemplate = async (
    clientId: string,
    data: PostCustomReportTemplateInput
  ) => {
    if (clientId && data) {
      return await tggClient.post(
        `/clients/${clientId}/custom-report-modules`,
        data
      );
    }
    console.warn(
      "clientId or data not provided, ReportsServices.postCustomReportTemplate "
    );
    return null;
  };

  updateCustomReportTemplate = async (
    clientId: string,
    customReportModuleId: string,
    data: UpdateCustomReportTemplateInput
  ) => {
    if (clientId && customReportModuleId && data) {
      return await tggClient.patch(
        `/clients/${clientId}/custom-report-modules/${customReportModuleId}`,
        data
      );
    }

    console.warn(
      "clientId or customReportModuleId or data not provided, ReportsServices.updateCustomReportTemplate "
    );
    return null;
  };

  removeCustomReportModule = async (
    clientId: string,
    customReportModuleId: string
  ) => {
    if (clientId && customReportModuleId) {
      return await tggClient.delete(
        `/clients/${clientId}/custom-report-modules/${customReportModuleId}`
      );
    }
    console.warn(
      "clientId or customReportModuleId not provided, ReportsServices.removeCustomReportModule"
    );
    return null;
  };

  getMonthlyReportModulesConfiguration = async ({
    queryKey,
  }: GetMonthlyReportModulesConfigurationInput) => {
    const [_key, { clientId, year, month, reportModuleConfigId }] = queryKey;
    if (clientId && year && month && reportModuleConfigId) {
      return await tggClient.get(
        `/clients/${clientId}/report-module-config/${reportModuleConfigId}/${year}/${month}`
      );
    }
    console.warn(
      "clientId or year or month or reportModuleConfigId not provided, ReportsServices.getMonthlyReportModulesConfiguration"
    );
    return null;
  };

  postMonthlyReportModulesConfiguration = async (
    clientId: string,
    year: string,
    month: string,
    reportModuleConfigId: string,
    data: PostMonthlyReportModulesConfigurationInput
  ) => {
    if (clientId && year && month && reportModuleConfigId && data) {
      return await tggClient.post(
        `/clients/${clientId}/report-module-config/${reportModuleConfigId}/year/${year}/month/${month}`,
        data
      );
    }
    console.warn(
      "clientId or year or month or reportModuleConfigId not provided, ReportsServices.postMonthlyReportModulesConfiguration"
    );
    return null;
  };

  getKPIFinancialPreview = async ({
    queryKey,
  }: GetKPIFinancialPreviewInput) => {
    const [_key, { clientId, kpiId, year, month, kpiType }] = queryKey;
    if (clientId && kpiId && year && month && kpiType) {
      return await tggClient.get(
        `/clients/${clientId}/kpi/${kpiId}/${year}/${month}/snapshots`,
        { params: { kpiType: kpiType } }
      );
    }
    return null;
  };

  getIncomeStatementClassification = async ({
    queryKey,
  }: GetIncomeStatementClassificationInput) => {
    const [_key, { clientId, year, month, type }] = queryKey;
    if (clientId && year && month && type) {
      return await tggClient.get(
        `/clients/${clientId}/reports/${year}/${month}/is-classification`,
        { params: { type } }
      );
    }
    console.warn(
      "Client Id, Year or Month is not provided, ReportsServices.getIncomeStatementClassification"
    );
    return null;
  };

  getTableOfContent = async ({ queryKey }: GetTableOfContentInput) => {
    const [_key, { clientId, reportTemplateId, year, month, notesNoOfPages }] =
      queryKey;
    if (clientId && reportTemplateId && year && month) {
      return await tggClient.get(
        `/clients/${clientId}/report-templates/${reportTemplateId}/${year}/${month}/table-of-content`,
        { params: { notesNoOfPages } }
      );
    }
    console.warn(
      "Client Id, Year or Month is not provided, ReportsServices.getTableOfContent"
    );
    return null;
  };

  deleteFinancialReport = async (
    clientId: string,
    financialPackageId: string,
    archiveProfitRecommendation: boolean = false
  ) => {
    if (clientId && financialPackageId) {
      return await tggClient.delete(
        `/clients/${clientId}/financial-packages/${financialPackageId}`,
        archiveProfitRecommendation
          ? { data: { archiveProfitRecommendation } }
          : undefined
      );
    }
    console.warn(
      "Client Id, Financial Package Id is not provided, ReportsServices.deleteFinancialReport"
    );
    return null;
  };

  getReportPublish = async ({
    queryKey,
  }: {
    queryKey: [
      string,
      { clientId: string; year: string; month: string; mode?: string }
    ];
  }) => {
    const [_key, { clientId, year, month, mode = "" }] = queryKey;
    if (clientId && year && month) {
      return await tggClient.get(
        `/clients/${clientId}/year/${year}/month/${month}/report-publish/pre-conditions`,
        { params: { mode } }
      );
    }
    console.warn(
      "Client Id, Year or Month is not provided, ReportsServices.getReportPublish"
    );
    return null;
  };

  postReportPublish = async (clientId: string, year: string, month: string) => {
    if (clientId && year && month) {
      return await tggClient.post(
        `/clients/${clientId}/year/${year}/month/${month}/report-publish`
      );
    }
    console.warn(
      "clientId or year or month not provided, ReportsServices.postReportPublish"
    );
    return null;
  };

  postReportUnpublish = async (
    clientId: string,
    year: string,
    month: string
  ) => {
    if (clientId && year && month) {
      return await tggClient.post(
        `/clients/${clientId}/year/${year}/month/${month}/report-unpublish`
      );
    }
    console.warn(
      "clientId or year or month not provided, ReportsServices.postReportUnpublish"
    );
    return null;
  };

  getCpConfigurations = async ({
    queryKey,
  }: {
    queryKey: [string, { clientId: string }];
  }) => {
    const [_key, { clientId }] = queryKey;
    if (clientId) {
      return await tggClient.get(`/clients/${clientId}/cp-configurations`);
    }
    console.warn("clientId not provided, ReportsServices.getCpConfigurations");
    return null;
  };

  addCustomTable = async (clientId: string, data: AddCustomTableData) => {
    if (clientId && data) {
      return await tggClient.post(`/clients/${clientId}/custom-tables`, data);
    }
    console.warn(
      "clientId or data not provided, ReportsServices.addCustomTable"
    );
    return null;
  };

  getCustomTableById = async ({
    queryKey,
  }: {
    queryKey: [
      string,
      { clientId: string; customTableId: string; year: number; month: number }
    ];
  }) => {
    const [_key, { clientId, customTableId, year, month }] = queryKey;
    if (clientId && customTableId) {
      return await tggClient.get(
        `/clients/${clientId}/custom-tables/${customTableId}`,
        { params: { year, month } }
      );
    }
    console.warn(
      "clientId or data not provided, ReportsServices.getCustomTableById"
    );
    return null;
  };

  updateCustomTable = async (
    clientId: string,
    customTableId: string,
    data: AddCustomTableData
  ) => {
    if (clientId && customTableId && data) {
      return await tggClient.put(
        `/clients/${clientId}/custom-tables/${customTableId}`,
        data
      );
    }
    console.warn(
      "clientId, customTableId or data not provided, ReportsServices.updateCustomTable"
    );
    return null;
  };

  deleteCustomTable = async (clientId: string, customTableId: string) => {
    if (clientId && customTableId) {
      return await tggClient.delete(
        `/clients/${clientId}/custom-tables/${customTableId}`
      );
    }
    console.warn(
      "clientId or customTableId not provided, ReportsServices.deleteCustomTable"
    );
    return null;
  };

  postDatEntryCustomTable = async (
    clientId: string,
    customTableId: string,
    data: {
      period: any;
      items: never[];
    }
  ) => {
    if (clientId && customTableId && data) {
      return await tggClient.post(
        `/clients/${clientId}/custom-tables/${customTableId}/data`,
        data
      );
    }
    console.warn(
      "clientId, data or customTableId not provided, ReportsServices.postDatEntryCustomTable"
    );
    return null;
  };

  getReportPublishPreConditions = async () => {
    return await tggClient.get("/report-publish/pre-conditions");
  };

  postClientReportPublishSettings = async (
    clientId: string,
    data: {
      reportValidationSettings: {
        validations: never[];
      };
    }
  ) => {
    if (clientId && data) {
      return await this.clientSettingsAPI.clientSettingsControllerAddReportValidationSettings(
        data,
        clientId
      );
    }
    console.warn(
      "clientId or data not provided, ReportsServices.postClientReportPublishSettings"
    );
    return null;
  };

  postAgencyReportPublishSettings = async (agencyId: string, data: any) => {
    if (agencyId && data) {
      return await this.agencySettingsAPI.agencySettingsControllerAddReportValidationSettings(
        data,
        agencyId
      );
    }
    console.warn(
      "agencyId or data not provided, ReportsServices.postAgencyReportPublishSettings"
    );
    return null;
  };

  getSalesByCustomersById = async ({
    queryKey,
  }: {
    queryKey: [
      string,
      {
        clientId: string;
        widgetId: string;
        year: number;
        month: number;
        reportModuleConfigId: string;
      }
    ];
  }) => {
    const [_key, { clientId, widgetId, year, month, reportModuleConfigId }] =
      queryKey;
    if (clientId && widgetId && reportModuleConfigId && year && month) {
      return await tggClient.get(
        `/clients/${clientId}/reports/year/${year}/month/${month}/sales-by-customer/${widgetId}`,
        { params: { reportModuleConfigId } }
      );
    }
    console.warn(
      "clientId, widgetId, reportModuleConfigId, year or month not provided, ReportsServices.getSalesByCustomersById"
    );
    return null;
  };

  postDataTableSettings = async (
    clientId: string,
    data: PostDataTableSettingsInput
  ) => {
    if (clientId && data) {
      return await tggClient.post(`/clients/${clientId}/data-tables`, data);
    }
    console.warn(
      "clientId or data not provided, ReportsServices.postDataTableSettings"
    );
    return null;
  };

  postChartsSettings = async (
    clientId: string,
    data: {
      id: string;
      type: string;
      chartType: string;
      year: string | null;
      month: string | null;
      isReportTemplate: boolean;
    }
  ) => {
    if (clientId && data) {
      return await tggClient.post(`/clients/${clientId}/chart`, data);
    }
    console.warn(
      "clientId or data not provided, ReportsServices.postChartsSettings"
    );
    return null;
  };

  postKpiSettings = async (
    clientId: string,
    data: {
      id: string;
      type: string;
      chartType: string;
      year: string | null;
      month: string | null;
      isReportTemplate: boolean;
    }
  ) => {
    if (clientId && data) {
      return await tggClient.post(`/clients/${clientId}/kpis`, data);
    }
    console.warn(
      "clientId or data not provided, ReportsServices.postKpiSettings"
    );
    return null;
  };

  getFinancialTemporaryPackages = async ({
    queryKey,
  }: {
    queryKey: [string, { clientId: string; year: string; pagination: boolean }];
  }) => {
    const [_key, { clientId, year, pagination = false }] = queryKey;
    if (clientId) {
      return await tggClient.get(
        `/clients/${clientId}/financial-packages-stage`,
        { params: { year, pagination } }
      );
    }
    console.warn(
      "clientId not provided, ReportsServices.getFinancialTemporaryPackages"
    );
    return null;
  };

  updateFinancialTemporaryPackage = async (
    clientId: string,
    financialPackageStageId: string,
    status: string
  ) => {
    if (clientId && financialPackageStageId && status) {
      return await tggClient.post(
        `/clients/${clientId}/financial-packages-stage/${financialPackageStageId}?status=${status}`
      );
    }
    console.warn(
      "clientId, financialPackageStageId or status not provided, ReportsServices.updateFinancialTemporaryPackage"
    );
    return null;
  };
}

const instance = new ReportsServices();

export default instance;
