import { userRoles } from "@utils/constant/permissionData";
import { roleGroupCodes } from "@utils/constant/users";
import { formatPhoneNumber } from "@utils/functions/common";

import { clientItemResponseHelper } from "../client";

function userInvitationItemRespHelper(item) {
  return {
    id: item?.id || "",
    invitationCode: item?.invitationCode || "",
    invitationStatus: item?.invitationStatus || "",
    isActive: item?.activeInfo?.active || "",
  };
}

// getParentAccountId:
// Checks if the first user role is tggUser.
// If true, returns the tenant ID or an empty string.
// If false, checks if there are more than one assigned client.
// If true, returns an empty string.
// If false, returns the agency ID of the first assigned client.
function getParentAccountId(item, userRoles) {
  if (item?.userRoles?.[0] === userRoles.tggUser) {
    return item?.userAgency?.id || "";
  }
  if (item?.assignedClients?.length > 1) {
    return "";
  }
  // DARP note: this line used to be:
  // return item?.assignedClients?.[0]?.parentClient?.id;
  // the question is, why is it used as parentAccountId?
  return item?.assignedClients?.[0]?.agency?.id;
}

// getClientName:
// Checks if the first user role is tggUser and if there's exactly one tggUserClient.
// If true, returns the name of the first tggUserClient or an empty string.
// If false, checks if the first user role is clientUser and if there's exactly one assigned client.
// If true, returns the name of the first assigned client or an empty string.
// If false, returns an empty string.
function getClientName(item, userRoles) {
  if (
    item?.userRoles?.[0] === userRoles.tggUser &&
    item?.tggUserClients?.length === 1
  ) {
    return item?.tggUserClients?.[0]?.name || "";
  }
  if (
    item?.userRoles?.[0] === userRoles.clientUser &&
    item?.assignedClients?.length === 1
  ) {
    return item?.assignedClients?.[0]?.name || "";
  }
  return "";
}

export function userItemResponseHelper(item) {
  return {
    id: item?.id || "",
    firstName: item?.firstName || "-",
    lastName: item?.lastName || "-",
    fullName: `${item?.firstName || ""} ${item?.lastName || ""}`,
    email: item?.emailAddress || "-",
    imageUrl: item?.imageUrl || "",
    phoneNumber: formatPhoneNumber(item?.phoneNumber),
    phoneNumberText: item?.phoneNumber || "",
    jobRole: item?.jobFunction?.[0] || "",
    jobRoles: item?.jobFunction || [],
    isSuperAdmin: item?.userRoles?.[0] === userRoles.superAdmin,
    isTGGUser: item?.userRoles?.[0] === userRoles.tggUser,
    isClientUser: item?.userRoles?.[0] === userRoles.clientUser,
    isGlobalUser: item?.userRoles?.[0] === userRoles.globalUser,
    userRole: item?.userRoles?.[0] || "",
    userRoles: item?.userRoles || [],
    isActive: !!item?.activeInfo?.active,
    invitation: userInvitationItemRespHelper(item?.invitation),
    permissions: item?.permissions || [],
    client: clientItemResponseHelper(item?.client),
    isMultiClient: item?.assignedClients?.length > 1,
    assignedClients: item?.assignedClients,
    roleGroup: item?.roleGroup,
    roleGroupCode: item?.roleGroup?.code,
    isOwner: item?.roleGroup?.code === roleGroupCodes.owner,
    isFullAccess: item?.roleGroup?.code === roleGroupCodes.fullAccess,
    isStandardAccess: item?.roleGroup?.code === roleGroupCodes.standardAccess,
    isReadOnlyAccess: item?.roleGroup?.code === roleGroupCodes.readOnly,
    parentAccountId: getParentAccountId(item, userRoles),
    agency: item?.userAgency,
    createdAt: item?.auditInfo?.createdAt,
    clientName: getClientName(item, userRoles),
  };
}

export function accountingTeamUserItemHelper(item) {
  return {
    id: item?.id || "",
    isActive: !!item?.activeInfo?.active,
    userDetails: userItemResponseHelper(item?.user),
    jobFunction: item?.jobFunction || "",
  };
}

export function advisorUserItemResponseHelper(item) {
  return {
    id: item?.id || "",
    email: item?.emailAddress || "",
    firstName: item?.firstName || "",
    lastName: item?.lastName || "",
    fullName: `${item?.firstName || ""} ${item?.lastName || ""}`,
    institutionName: item?.institutionName || "",
    advisorTypeCode: item?.advisorType?.code || "",
    advisorTypeName: item?.advisorType?.name || "",
  };
}

export function clientUserItemHelper(item) {
  return {
    id: item?.id || "",
    isActive: !!item?.activeInfo?.active,
    userDetails: userItemResponseHelper(item),
  };
}
