import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activePackageId: "",
};

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setActivePackageId: (state, action) => {
      state.activePackageId = action.payload;
    },
  },
});

export const { setActivePackageId } = reportSlice.actions;
