export const currency = {
  code: "USD",
  symbol: "$",
  name: "US Dollar",
};

export const localKeys = {
  clientView: "TGG_CLIENT_VIEW_TYPE",
  tggToken: "TGG_AUTH_TOKEN",
  refetchTggToken: "TGG_REFETCH_AUTH_TOKEN",
  darkMode: "TGG_DARK_MODE",
  sideMenu: "TGG_SIDE_MENU",
  QBOSession: "TGG_QBO_SESSION",
  reportSideMenu: "TGG_REPORT_SIDE_MENU",
  fullReportAPICallsStarted: "FULL_REPORT_API_CALLS_STARTED",
  fullReportAPICallsDone: "FULL_REPORT_API_CALLS_DONE",
  clientPortalView: "TGG_CLIENT_PORTAL_VIEW",
  qboTaskId: "TGG_QBO_TASK_ID",
  qboTaskIdForReportTask: "TGG_QBO_TASK_ID_FOR_REPORT_TASK",
};

export const viewKeys = {
  list: "LIST",
  grid: "GRID",
};

export const statusKeys = {
  active: "ACTIVE",
  inactive: "IN-ACTIVE",
};

export const months = [
  {
    title: "January",
    value: 1,
  },
  {
    title: "February",
    value: 2,
  },
  {
    title: "March",
    value: 3,
  },
  {
    title: "April",
    value: 4,
  },
  {
    title: "May",
    value: 5,
  },
  {
    title: "June",
    value: 6,
  },
  {
    title: "July",
    value: 7,
  },
  {
    title: "August",
    value: 8,
  },
  {
    title: "September",
    value: 9,
  },
  {
    title: "October",
    value: 10,
  },
  {
    title: "November",
    value: 11,
  },
  {
    title: "December",
    value: 12,
  },
];

export const clientProfileIds = {
  clientDetails: "CLIENT_DETAILS",
  organizationProfile: "ORGANIZATION_PROFILE",
  financialProfile: "FINANCIAL_PROFILE",
  teamAdvisor: "TEAM_ADVISOR",
};

export const clientTeamAdvisorIds = {
  managementOwnership: "MANAGEMENT_OWNERSHIP",
  companyAdvisors: "COMPANY_ADVISORS",
  accountingTeam: "ACCOUNTING_TEAM",
};

export const clientProfileOption = [
  {
    title: "client.clientDetails",
    id: clientProfileIds.clientDetails,
  },
  {
    title: "client.organizationProfile.title",
    id: clientProfileIds.organizationProfile,
  },
  {
    title: "client.financialProfile.title",
    id: clientProfileIds.financialProfile,
  },
  {
    title: "client.teamAdvisor",
    id: clientProfileIds.teamAdvisor,
  },
];

export const clientTeamAdvisor = [
  {
    title: "client.teamAdvisors.managementOwnership",
    id: clientTeamAdvisorIds.managementOwnership,
  },
  {
    title: "client.teamAdvisors.companyAdvisors",
    id: clientTeamAdvisorIds.companyAdvisors,
  },
  {
    title: "client.teamAdvisors.accountingTeam",
    id: clientTeamAdvisorIds.accountingTeam,
  },
];

export const addTGGUserIds = {
  userDetails: "USER_DETAILS",
  permissions: "PERMISSIONS",
  review: "REVIEW_USER_DETAILS",
};

export const addTGGUserTabs = [
  {
    title: "user.tgg-user.userDetails",
    id: addTGGUserIds.userDetails,
  },
  {
    title: "user.tgg-user.permissions",
    id: addTGGUserIds.permissions,
  },
  {
    title: "user.tgg-user.review",
    id: addTGGUserIds.review,
  },
];

export const coaReportTypeIds = {
  incomeStatement: "income-statement",
  balanceSheet: "balance-sheet",
  cashFlowStatement: "cash-flow-statement",
};

export const mapAISReportTypeIds = {
  incomeStatement: "income-statement",
  balanceSheet: "balance-sheet",
  cashFlowStatement: "cash-flow-statement",
};

export const reportTypeIds = {
  incomeStatement: "income-statement",
  balanceSheet: "balance-sheet",
  cashFlowStatement: "cash-flow-statement",
};

export const coaReportType = [
  {
    title: "common.incomeStatement",
    value: coaReportTypeIds.incomeStatement,
  },
  { title: "common.balanceSheet", value: coaReportTypeIds.balanceSheet },
];

export const financialYearIds = {
  calendar: "calendar",
  specificMonth: "specific-month",
};

export const sortingType = {
  ascending: "ASC",
  descending: "DESC",
};
