import { tggTenantClient } from "@services/AxiosConfig";
import { QueryFunctionContext } from "react-query";

export interface Permissions {
  items: PermissionsItem[];
  recordsPerPage: number;
  totalRecords: number;
  currentPageNumber: number;
  totalPages: number;
}

export interface PermissionsItem {
  id: string;
  name: string;
  description?: string;
  code: string;
  activeInfo: ActiveInfo;
  auditInfo: AuditInfo;
  archiveInfo: ArchiveInfo;
  permissionCategory: null;
}

export interface ActiveInfo {
  active: boolean;
  activeStatusChangedAt: null;
}

export interface AuditInfo {
  createdAt: string;
  createdBy: null;
  updatedAt: string;
  updatedBy: null;
}

export interface ArchiveInfo {
  archived: boolean;
  archivedStatusChangedAt: null;
}

class PermissionsServices {
  // GET ALL PERMISSIONS
  getAllPermissions = async ({
    queryKey,
  }: {
    queryKey: [
      string,
      {
        sortDir: string;
        sortBy: string;
        take: number;
        skip: number;
        searchTerm: string;
        include: string;
        categoryCode: string;
        active: boolean | null;
      }
    ];
  }) => {
    const [
      _key,
      {
        sortDir = "ASC",
        sortBy = "id",
        take = 10,
        skip = 0,
        searchTerm = "",
        include = "",
        categoryCode = "",
        active = null,
      },
    ] = queryKey;

    return await tggTenantClient.get<never, Permissions>("/permissions", {
      params: {
        sortDir,
        sortBy,
        take,
        skip,
        searchTerm,
        include,
        categoryCode,
        active,
      },
    });
  };

  // GET PERMISSIONS BY CRITERIA
  getPermissionsByCriteria = async ({
    queryKey,
  }: {
    queryKey: [string, { criteria: string; mode: string }];
  }) => {
    const [_key, { criteria, mode = "id" }] = queryKey;

    if (criteria && mode) {
      return await tggTenantClient.get<never, PermissionsItem>(
        `/permissions/${criteria}`,
        {
          params: { mode },
        }
      );
    }

    console.warn(
      "Criteria or mode not provided, PermissionsServices.getPermissionsByCriteria"
    );
    return null;
  };

  // GET PERMISSIONS BY CATEGORY
  getPermissionsByCategory = async ({
    queryKey,
  }: {
    queryKey: [string, { code: string }];
  }) => {
    const [_key, { code }] = queryKey;

    if (code) {
      return await tggTenantClient.get<never, Permissions>(
        `/permission-categories/${code}/permissions`
      );
    }

    console.warn(
      "Code not provided, PermissionsServices.getPermissionsByCategory"
    );
    return null;
  };

  // GET PERMISSION CATEGORIES
  getPermissionCategories = async ({
    queryKey,
  }: QueryFunctionContext<
    [string, { include: string; searchTerm: string; active: boolean | null }]
  >) => {
    const [_key, { include = "", searchTerm = "", active = null }] = queryKey;

    const response = await tggTenantClient.get<never, Permissions>(
      "/permission-categories",
      {
        params: { include, searchTerm, active },
      }
    );
    return response;
  };

  // ACTIVATE PERMISSION
  activatePermission = async (permissionId: string) => {
    if (permissionId) {
      return await tggTenantClient.post<never, boolean>(
        `/permissions/${permissionId}/activate`
      );
    }
    console.warn(
      "PermissionId not provided, PermissionsServices.activatePermission"
    );
    return null;
  };

  // ARCHIVE PERMISSION
  archivePermission = async (permissionId: string) => {
    if (permissionId) {
      return await tggTenantClient.post<never, boolean>(
        `/permissions/${permissionId}/archive`
      );
    }
    console.warn(
      "PermissionId not provided, PermissionsServices.archivePermission"
    );
    return null;
  };

  // RESTORE PERMISSION
  restorePermission = async (permissionId: string) => {
    if (permissionId) {
      return await tggTenantClient.post<never, boolean>(
        `/permissions/${permissionId}/restore`
      );
    }
    console.warn(
      "PermissionId not provided, PermissionsServices.restorePermission"
    );
    return null;
  };

  // ACTIVATE PERMISSION CATEGORY
  activatePermissionCategory = async (permissionCategoryId: string) => {
    if (permissionCategoryId) {
      return await tggTenantClient.post<never, boolean>(
        `/permission-categories/${permissionCategoryId}/activate`
      );
    }
    console.warn(
      "PermissionCategoryId not provided, PermissionsServices.activatePermissionCategory"
    );
    return null;
  };

  // DEACTIVATE PERMISSION CATEGORY
  deactivatePermissionCategory = async (permissionCategoryId: string) => {
    if (permissionCategoryId) {
      return await tggTenantClient.post<never, boolean>(
        `/permission-categories/${permissionCategoryId}/deactivate`
      );
    }
    console.warn(
      "PermissionCategoryId not provided, PermissionsServices.deactivatePermissionCategory"
    );
    return null;
  };

  // ARCHIVE PERMISSION CATEGORY
  archivePermissionCategory = async (permissionCategoryId: string) => {
    if (permissionCategoryId) {
      return await tggTenantClient.post<never, boolean>(
        `/permission-categories/${permissionCategoryId}/archive`
      );
    }
    console.warn(
      "PermissionCategoryId not provided, PermissionsServices.archivePermissionCategory"
    );
    return null;
  };

  // RESTORE PERMISSION CATEGORY
  restorePermissionCategory = async (permissionCategoryId: string) => {
    if (permissionCategoryId) {
      return await tggTenantClient.post<never, boolean>(
        `/permission-categories/${permissionCategoryId}/restore`
      );
    }
    console.warn(
      "PermissionCategoryId not provided, PermissionsServices.restorePermissionCategory"
    );
    return null;
  };
}

const instance = new PermissionsServices();

export default instance;
