export function clientConditionResponseHelper(data) {
  let clientStatus = {
    isAISSetupDone: false,
    isCOASetupDone: false,
    isAISAccountsDownloaded: false,
    isAISMappingDone: false,
    isReportTemplateSetupDone: false,
  };
  if (data?.preConditions?.length > 0) {
    data.preConditions.forEach((c) => {
      switch (c.condition) {
        case "ais-profile-setup":
          clientStatus.isAISSetupDone = c.complete;
          break;
        case "coa-setup":
          clientStatus.isCOASetupDone = c.complete;
          break;
        case "ais-accounts-download":
          clientStatus.isAISAccountsDownloaded = c.complete;
          break;
        case "ais-account-mappings":
          clientStatus.isAISMappingDone = c.complete;
          break;
        case "report-template-setup":
          clientStatus.isReportTemplateSetupDone = c.complete;
          break;
        default:
          break;
      }
    });
  }
  return clientStatus;
}
