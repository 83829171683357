import { useState } from "react";

const useObjState = <T>(initialState: T) => {
  const [state, setState] = useState<T>(initialState);

  const handleState = (key: keyof T, value: T[keyof T]) => {
    setState((prevState) => ({ ...prevState, [key]: value }));
  };

  const clearState = () => {
    setState(initialState);
  };

  return [state, setState, handleState, clearState] as const;
};

export default useObjState;
