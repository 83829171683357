import ReportsServices from "@services/APIs/Reports";
import { useQuery } from "react-query";

export function useClientPreConditions(clientId: string) {
  const {
    data: conditionsDetails,
    isSuccess: isConditionsFetched,
    refetch,
  } = useQuery(
    [
      `/get/clients/${clientId}/reports/pre-conditions`,
      {
        clientId: clientId,
      },
    ],
    ReportsServices.getClientPreConditions,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!clientId,
    }
  );

  return { data: isConditionsFetched ? conditionsDetails : null, refetch };
}
