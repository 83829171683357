import { KPIInputItem } from "@utils/helper/kpis";

export const kpiConstant = {
  label: "Constant",
  type: "constant",
};

const operatorItemIds = {
  addition: "addition",
  subtraction: "subtraction",
  multiplication: "multiplication",
  division: "division",
};

export const bracketItemIds = {
  open: "open",
  close: "close",
};

export const formulaItemTypes = {
  operator: "OPERATOR",
  bracket: "BRACKET",
  input: "INPUT",
} as const;
export type FormulaItemType =
  (typeof formulaItemTypes)[keyof typeof formulaItemTypes];

export const operatorItems = [
  {
    id: operatorItemIds.addition,
    label: "+",
  },
  {
    id: operatorItemIds.subtraction,
    label: "-",
  },
  {
    id: operatorItemIds.multiplication,
    label: "*",
  },
  {
    id: operatorItemIds.division,
    label: "/",
  },
];

export const bracketItems = [
  {
    id: bracketItemIds.open,
    label: "(",
    optionalLabel: "[",
  },
  {
    id: bracketItemIds.close,
    label: ")",
    optionalLabel: "]",
  },
];

export function isKPIInputItemConstant(
  inputItem?: KPIInputItem
): inputItem is KPIInputItem & {
  selectedAccount: { dataSourceType: "constant" };
} {
  return inputItem?.selectedAccount?.dataSourceType === kpiConstant.type;
}
