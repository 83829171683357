import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Agency } from "@utils/types/agency";
import { StatusFilterEnum } from "@utils/types/global";

const TGG_CLIENT_VIEW_TYPE = "TGG_CLIENT_VIEW_TYPE";

const initialState = () => {
  let initialView = "GRID";
  if (typeof window !== "undefined") {
    const savedViewType = localStorage.getItem(TGG_CLIENT_VIEW_TYPE) || "GRID";
    if (["GRID", "LIST"].includes(savedViewType)) {
      initialView = savedViewType as "GRID" | "LIST";
    }
  }
  return {
    agencies: [] as Agency[],
    isLoading: true,
    totalPages: 0,
    currentPageNumber: 0,
    searchParams: {
      view: initialView as "GRID" | "LIST",
      term: "",
      status: StatusFilterEnum.ACTIVE,
      sort: {
        by: "name",
        direction: "ASC" as "ASC" | "DESC",
      },
      skip: 0,
    },
  };
};
type State = ReturnType<typeof initialState>;

export const agenciesSlice = createSlice({
  name: "agencies",
  initialState,
  reducers: {
    setView(state, action: PayloadAction<"GRID" | "LIST">) {
      state.searchParams.view = action.payload;
      localStorage.setItem(TGG_CLIENT_VIEW_TYPE, action.payload);
    },
    setSearchParams(
      state,
      action: PayloadAction<Partial<State["searchParams"]>>
    ) {
      state.searchParams = {
        ...state.searchParams,
        ...action.payload,
        sort: {
          ...state.searchParams.sort,
          ...action.payload.sort,
        },
      };
    },
  },
});

export const agenciesPageActions = agenciesSlice.actions;
