import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum AI_COMMENTARY_TOPICS {
  HUB_AI_EXECUTIVE_SUMMARY = "HUB AI Executive Summary",
  REVENUE = "Revenue",
  COGS = "COGS",
  GROSS_PROFIT = "Gross Profit",
  OPERATING_EXPENSE = "Operating Expense",
  NET_OPERATING_INCOME = "Net Operating Income",
  NET_INCOME = "Net Income",
  TOTAL_ASSETS = "Total Assets",
  TOTAL_LIABILITIES = "Total Liabilities",
  TOTAL_EQUITY = "Total Equity",
  CASH_FLOW = "Cash Flow",
  OTHER_TOPIC = "Other Topic",
}

const initialState = {
  aiCommentaryWidget: {
    selectedOptions: {} as Partial<Record<AI_COMMENTARY_TOPICS, boolean>>,
  },
};

export const configureExecutiveSummarySlice = createSlice({
  name: "configureExecutiveSummary",
  initialState,
  reducers: {
    toggleAiWidgetOption(state, action: PayloadAction<AI_COMMENTARY_TOPICS>) {
      const topic = action.payload;
      if (topic === AI_COMMENTARY_TOPICS.HUB_AI_EXECUTIVE_SUMMARY) {
        state.aiCommentaryWidget.selectedOptions = {
          [AI_COMMENTARY_TOPICS.HUB_AI_EXECUTIVE_SUMMARY]:
            !state.aiCommentaryWidget.selectedOptions[
              AI_COMMENTARY_TOPICS.HUB_AI_EXECUTIVE_SUMMARY
            ],
        };
      } else if (state.aiCommentaryWidget.selectedOptions[topic]) {
        delete state.aiCommentaryWidget.selectedOptions[topic];
      } else {
        state.aiCommentaryWidget.selectedOptions[topic] = true;
      }
      if (
        !state.aiCommentaryWidget.selectedOptions[
          AI_COMMENTARY_TOPICS.HUB_AI_EXECUTIVE_SUMMARY
        ]
      ) {
        delete state.aiCommentaryWidget.selectedOptions[
          AI_COMMENTARY_TOPICS.HUB_AI_EXECUTIVE_SUMMARY
        ];
      }
    },
    preloadInitialOptions(
      state,
      action: PayloadAction<Array<AI_COMMENTARY_TOPICS>>
    ) {
      state.aiCommentaryWidget.selectedOptions = {};
      action.payload.forEach((topic) => {
        state.aiCommentaryWidget.selectedOptions[topic] = true;
      });
    },
  },
});

export const { toggleAiWidgetOption, preloadInitialOptions } =
  configureExecutiveSummarySlice.actions;
