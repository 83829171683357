import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { tggClient } from "@services/AxiosConfig";
import { HubChart } from "@utils/charts/types";
import { HYDRATE } from "next-redux-wrapper";

export type Card = {
  layoutId: string;
  cardId: string;
  title: string;
  description: string | null;
  size: number;
  position: number;
  cardType:
    | "REVENUE"
    | "COGS"
    | "GP"
    | "OPEX"
    | "NOI"
    | "NI"
    | "PR"
    | "CG"
    | "TBL"
    | "KPI";
  content: Array<{
    text: string;
    metadata: HubChart;
  }>;
};

type UpdateCardPayload = {
  layoutId: string;
  clientId: string;
} & (
  | {
      initialPosition: number;
      finalPosition: number;
    }
  | {
      size: number;
    }
);

type FetchCardsPayload = {
  clientId: string;
  period: string;
};
export type FetchCardsResponse = {
  cards: Array<Card>;
  clientId: string;
  userId: string;
  period: string;
};
export const fetchCards = createAsyncThunk(
  "insights/fetchCards",
  async (payload: FetchCardsPayload) => {
    const response = await tggClient.get<never, FetchCardsResponse>(
      `/clients/${payload.clientId}/insights/cards/${payload.period}`
    );
    if ("cards" in response) {
      return response;
    }
    throw new Error("Error fetching cards");
  }
);

export const updateCard = createAsyncThunk(
  "insights/updateCard",
  async (payload: UpdateCardPayload) => {
    const { clientId, ...requestBody } = payload;
    if ("finalPosition" in requestBody) {
      requestBody.finalPosition = calculateNewIndex({
        dragIndex: requestBody.initialPosition,
        dropIndex: requestBody.finalPosition,
        dropSide: "left",
      });

      if (requestBody.finalPosition === requestBody.initialPosition) {
        return;
      }
    }
    await tggClient.patch(`/clients/${clientId}/insights/layout`, requestBody);
  }
);

const initialState = {
  cards: [] as Array<Card>,
  draggingCardId: null as string | null,
  resizingCardId: null as string | null,
};
type State = typeof initialState;
export const insightsSlice = createSlice({
  name: "insights",
  initialState,
  reducers: {
    setCards: (state, action: PayloadAction<Array<Card>>) => {
      state.cards = action.payload;
    },
    moveCard: (
      state,
      action: PayloadAction<{
        dragIndex: number;
        dropIndex: number;
        dropSide: "left" | "right";
      }>
    ) => {
      const [draggedCard] = state.cards.splice(action.payload.dragIndex, 1);
      let newIndex = calculateNewIndex(action.payload);
      state.cards.splice(newIndex, 0, draggedCard);
      state.draggingCardId = null;
    },
    resizeCard: (
      state,
      action: PayloadAction<{ id: string; parentFraction: number }>
    ) => {
      for (let i = 0; i < state.cards.length; i++) {
        if (state.cards[i].layoutId === action.payload.id) {
          state.cards[i].size = action.payload.parentFraction;
          break;
        }
      }
      state.resizingCardId = null;
    },
    setDraggingCard: (state, action: PayloadAction<string | null>) => {
      state.draggingCardId = action.payload;
    },
    setResizingCard: (state, action: PayloadAction<string | null>) => {
      state.resizingCardId = action.payload;
    },
    resetCards: (state) => {
      for (let i = 0; i < state.cards.length; i++) {
        state.cards[i].size = 2;
        state.cards[i].position = i;
      }
    },
  },
  extraReducers(builder) {
    // @ts-ignore
    builder.addCase(
      HYDRATE,
      (state, action: PayloadAction<{ insights: State }>) => {
        state.cards = action.payload.insights.cards;
      }
    );

    builder.addCase(fetchCards.fulfilled, (state, action) => {
      state.cards = action.payload.cards;
    });
    builder.addCase(fetchCards.rejected, (state, action) => {
      console.error(action.error);
    });
  },
});

export const insightsActions = insightsSlice.actions;

/** This function calculates the new index of a card given where it came from and where it's being dropped at */
const calculateNewIndex = (payload: {
  dragIndex: number;
  dropIndex: number;
  dropSide: "left" | "right";
}) => {
  let newIndex = payload.dropIndex;
  if (payload.dropSide === "right" && payload.dragIndex > payload.dropIndex) {
    newIndex += 1;
  }
  if (payload.dropSide === "left" && payload.dragIndex < payload.dropIndex) {
    newIndex -= 1;
  }
  return newIndex;
};
