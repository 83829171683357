import { baseURI } from "@utils/constant/msalConfig";
import { routes } from "@utils/route";
import Humanize from "humanize-plus";
import _, { isEmpty } from "lodash";

export function getRandom(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function getQueryString(object, returnObject?: boolean) {
  if (Object.keys(object).some((key) => !!object[key])) {
    Object.keys(object).forEach((key) => {
      if (!object[key]) {
        delete object[key];
      }
    });
    if (returnObject) return object;
    const qs = Object.keys(object)
      .map((key) => {
        if (object[key]) {
          return `${key}=${object[key]}`;
        }
        return;
      })
      .join("&");
    return qs;
  }
  return "";
}

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return "-";
}

export function replaceRedirectURL(url) {
  return url.replace(
    /&redirect_uri=.*&/gm,
    `&redirect_uri=${baseURI.replace(/.$/gm, "")}${routes.client.qboCallback}&`
  );
}

export function createUUID() {
  let dt = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}

export const displayNumberFormat = (amount, decimals = 0) => {
  if (amount === undefined) return "0";
  if (amount === null) return "";
  const value = +amount;
  if (_.isFinite(value)) {
    if (value < 0) {
      return `(${Humanize.formatNumber(Math.abs(value), decimals)})`;
    }
    return Humanize.formatNumber(value, decimals);
  }
  return "0";
};

export const displayCurrencyFormat = (amount) => {
  const value = +amount;
  if (_.isFinite(value)) {
    if (value < 0) {
      return `($${Humanize.formatNumber(Math.abs(value))})`;
    }
    return `$${Humanize.formatNumber(value)}`;
  }
  return amount;
};

export function haveSearchTerm(searchTerm = "", string1 = "", string2 = "") {
  if (isEmpty(searchTerm)) return true;

  return `${string1}${string2}`
    .replaceAll(" ", "")
    .toLowerCase()
    .includes(searchTerm.replaceAll(" ", "").toLowerCase());
}

function getRandomIntegerInLimit(maxLimit, minLimit) {
  return Math.floor(Math.random() * (maxLimit - minLimit) + minLimit);
}

export function getUniqRandomInteger(randomNumbers = [], maxLimit, minLimit) {
  const randomNumber = getRandomIntegerInLimit(maxLimit, minLimit);
  if (randomNumbers.length > 0) {
    if (randomNumbers.includes(randomNumber)) {
      return getUniqRandomInteger(randomNumbers, maxLimit, minLimit);
    }
  }
  return randomNumber;
}

export function getDimensions(ele) {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop - 75;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
}

export function downloadAllFile(filename, text, type) {
  const url = window.URL.createObjectURL(
    new Blob([text], { type: `"application/${type}"` })
  );
  let element = document.createElement("a");
  element.href = url;
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function downloadFile(filename, text) {
  const url = window.URL.createObjectURL(
    new Blob([text], { type: "application/pdf" })
  );
  let element = document.createElement("a");
  element.href = url;
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function downloadCSVFile(filename, text) {
  const url = window.URL.createObjectURL(
    new Blob([text], { type: "application/csv" })
  );
  let element = document.createElement("a");
  element.href = url;
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function getZeroArrayOfLength(length) {
  const arr = [];
  for (let i = 0; i < length; i++) {
    arr.push(0);
  }
  return arr;
}

export const handleUrlChange = (url) => {
  let checkAndAddHttps = url;

  if (!checkAndAddHttps.startsWith("http")) {
    if (checkAndAddHttps.startsWith("www.")) {
      checkAndAddHttps = `https://${checkAndAddHttps}`;
    } else {
      checkAndAddHttps = `https://www.${checkAndAddHttps}`;
    }
  }
  return checkAndAddHttps;
};

export const getYears = () => {
  const currentYear = new Date().getFullYear();
  const upcomingYear = currentYear + 1;

  if (new Date().getMonth() < 3) {
    return [currentYear, upcomingYear, currentYear - 1];
  }
  return [currentYear, upcomingYear];
};

export const displayNumberFormatCustomTable = (amount) => {
  if (amount === undefined) return "0";
  if (amount === null) return "";
  const value = +amount;
  if (_.isFinite(value)) {
    if (value < 0) {
      return `(${Humanize.formatNumber(Math.abs(value))})`;
    }
    return Humanize.formatNumber(value);
  }
  return "0";
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param decimalPlaces Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function formatDataSize(bytes: number, decimalPlaces = 2) {
  if (Math.abs(bytes) < 1024) {
    return bytes || 0 + " B";
  }
  const units = ["KB", "MB", "GB", "TB", "PB"];
  let unitIndex = -1;
  const precisionFactor = 10 ** decimalPlaces;
  do {
    bytes /= 1024;
    ++unitIndex;
  } while (
    Math.round(Math.abs(bytes) * precisionFactor) / precisionFactor >= 1024 &&
    unitIndex < units.length - 1
  );

  return bytes.toFixed(decimalPlaces) + " " + units[unitIndex];
}

export function copyValue(value) {
  let dummy = document.createElement("TEXTAREA");
  document.body.appendChild(dummy);
  dummy.innerHTML = value;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}
