import { tggClient } from "@services/AxiosConfig";
import { QueryFunctionContext } from "react-query";

// Interfaces for response data
interface ActiveInfo {
  active: boolean;
  activeStatusChangedAt: string;
}

interface AuditInfo {
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
}

interface ReportType {
  id: string;
  name: string;
  description: string;
  code: string;
  activeInfo: ActiveInfo;
  auditInfo: AuditInfo;
}

export interface DfmType {
  id: string;
  title: string;
  code: string;
  createSection: boolean;
  sectionTitle: string;
  description: string;
  displayOrder: number;
  reportType: ReportType;
  addedToReport: boolean;
}

interface DfmTypesResponse {
  items: DfmType[];
}

// Interfaces for input parameters
interface IGetDfmTypes {
  clientId: string;
  reportTypeId: string | null;
}

class DfmServices {
  // GET DFM TYPES
  getDfmTypes = async ({
    queryKey,
  }: QueryFunctionContext<
    [string, IGetDfmTypes]
  >): Promise<DfmTypesResponse> => {
    const [_key, { clientId = "", reportTypeId = null }] = queryKey;
    const response = await tggClient.get<never, DfmTypesResponse>(
      `/dynamic-financial-statement-types`,
      {
        params: { clientId, reportTypeId },
      }
    );
    return response;
  };
}

const instance = new DfmServices();

export default instance;
