export const permissionAssignmentTypes = {
  fromExistingUser: "FROM_EXISTING_USER",
  fromExistingPersona: "FROM_EXISTING_PERSONA",
  custom: "CUSTOM",
};

export function permissionMode(type) {
  switch (type) {
    case permissionAssignmentTypes.fromExistingUser:
      return "existing-user";
    case permissionAssignmentTypes.fromExistingPersona:
      return "existing-persona";
    case permissionAssignmentTypes.custom:
      return "specific-permission";
    default:
      return "specific-permission";
  }
}

export const userRoles = {
  superAdmin: "super-admin",
  tggUser: "tgg-user",
  clientUser: "client-user",
  globalUser: "global-user",
};

export function getUserRole(type) {
  switch (type) {
    case userRoles.superAdmin:
      return "Super Admin";
    case userRoles.tggUser:
      return "HUB Analytics User";
    case userRoles.clientUser:
      return "Client User";
    default:
      return "";
  }
}
