import useLocalState from "@hooks/useLocalState";
import useMediaQuery from "@hooks/useMediaQuery";
import { localKeys } from "@utils/constant";
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

const ThemeContext = createContext({
  isMobile: false,
  showFullNav: false,
  isTablet: false,
  isDark: false,
  keepMenuOpen: false,
  isLaptop: false,
  isLargeLaptop: false,
  setShowFullNav: (_showFullNav: boolean) => {},
  setKeepMenuOpen: (_keepMenuOpen: boolean, _stateKey: string) => {},
  setIsDark: (_isDark: boolean, _stateKey: string) => {},
});

// TODO: Theme redux slice.
export const useTheme = () => useContext(ThemeContext);

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const [showFullNav, setShowFullNav] = useState<boolean>(true);
  const isTablet = useMediaQuery("(min-width:1024px)");
  const isLaptop = useMediaQuery("(min-width:1440px)");
  const isLargeLaptop = useMediaQuery("(min-width:1636px)");
  const [isDark, setIsDark] = useLocalState(false, localKeys.darkMode);
  const [keepMenuOpen, setKeepMenuOpen] = useLocalState(
    true,
    localKeys.sideMenu
  );

  useEffect(() => {
    if (!isTablet) {
      setShowFullNav(false);
    }
  }, [isTablet]);

  useEffect(() => {
    if (document.documentElement) {
      const root = document.documentElement;
      if (isDark) {
        root.className = "dark";
      } else {
        root.className = "";
      }
    }
  }, [isDark]);

  const value = useMemo(
    () => ({
      isMobile,
      showFullNav,
      isTablet,
      isDark,
      keepMenuOpen,
      isLaptop,
      isLargeLaptop,
      setShowFullNav,
      setIsDark,
      setKeepMenuOpen,
    }),
    [
      isMobile,
      showFullNav,
      isTablet,
      isDark,
      keepMenuOpen,
      isLaptop,
      isLargeLaptop,
    ]
  );

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export default ThemeProvider;
