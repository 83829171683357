import { tggClient } from "@services/AxiosConfig";
import {
  errorHelperAIS,
  getAccessToken,
} from "@services/AxiosConfig/helperFunction";
import { RequestInit } from "next/dist/server/web/spec-extension/request";
import { z } from "zod";

interface GetAISProfileParams {
  clientId: string;
}

interface GetDataIngestURLsParams {
  clientId: string;
  reportEndDate: string;
  isQBD: boolean;
  isQBO: boolean;
}

interface GetQBODataResyncParams {
  clientId: string;
  isOverWrite?: boolean;
  reportEndDate: string | null;
  reportStartDate?: string | null;
}

interface GetAISDataProcessingCheckParams {
  clientId: string;
}

interface DisconnectQBOAccountParams {
  clientId: string;
}

const aisProfileSchema = z
  .object({
    status: z.string(),
    data: z.object({
      id: z.string(),
      active: z.boolean().optional(),
      completed: z.boolean().optional(),
      completed_at: z.string().optional(),
      source: z.string().optional(),
      last_sync_date: z.string().optional(),
    }),
  })
  .strict();

const dataIngestURLsSchema = z
  .object({
    url: z.string(),
  })
  .strict();

const qboDataResyncSchema = z
  .object({
    status: z.string(),
    data: z
      .object({
        task_id: z.string(),
      })
      .optional(),
  })
  .strict();

const aisDataProcessingCheckSchema = z
  .object({
    status: z.string(),
    data: z.object({
      task_id: z.string().optional(),
      import_processing: z.boolean(),
    }),
  })
  .strict();

const disconnectQBOAccountStatusSchema = z
  .object({
    status: z.string(),
    metaData: z.array(
      z.object({
        status: z.string(),
        message: z.string(),
      })
    ),
  })
  .strict();

class AISProfileServices {
  private async fetchData<T>(
    url: string,
    schema: z.ZodSchema<T>,
    options?: RequestInit
  ): Promise<string | T> {
    const response = await fetch(
      process.env.NEXT_PUBLIC_TGG_AIS_API_BASE_URI + url,
      {
        ...options,
        headers: {
          Authorization: getAccessToken(),
          ...options?.headers,
        },
      }
    );
    const data = await response.json();
    const parsedData = schema.safeParse(data);
    if (!parsedData.success) {
      // eslint-disable-next-line no-console
      console.trace(response.url, parsedData.error.errors);
      const apiError = errorHelperAIS(data);
      return apiError || (data as T);
    }
    return parsedData.data;
  }

  getAISProfile = async ({
    queryKey,
  }: {
    queryKey: [string, GetAISProfileParams];
  }) => {
    const [_key, { clientId }] = queryKey;
    if (clientId) {
      const url = `/clients/${clientId}/ais-profile/`;
      return this.fetchData(url, aisProfileSchema);
    }
    console.warn("clientId is not provided, AISProfileServices.getAISProfile");
    return null;
  };

  getDataIngestURLs = async ({
    clientId,
    reportEndDate,
    isQBD,
    isQBO,
  }: GetDataIngestURLsParams) => {
    if (clientId && reportEndDate && (isQBD || isQBO)) {
      const url = `/clients/${clientId}/${
        isQBD ? "qbd" : "qbo"
      }/data-ingest-endpoints/${reportEndDate}/`;
      return this.fetchData(url, dataIngestURLsSchema);
    }
    console.warn(
      "clientId or reportEndDate is not provided, AISProfileServices.getDataIngestURLs"
    );
    return null;
  };

  getQBODataResync = async ({
    clientId,
    isOverWrite = false,
    reportEndDate,
    reportStartDate = null,
  }: GetQBODataResyncParams) => {
    if (clientId && reportEndDate) {
      const url = `/clients/${clientId}/qbo/import/ais-data/`;
      const params = new URLSearchParams({
        overwrite: isOverWrite.toString(),
        report_end_date: reportEndDate,
        report_start_date: reportStartDate ?? "",
      });
      return this.fetchData(`${url}?${params}`, qboDataResyncSchema);
    }
    console.warn(
      "clientId or reportEndDate is not provided, AISProfileServices.getQBODataResync"
    );
    return null;
  };

  getAISDataProcessingCheck = async ({
    queryKey,
  }: {
    queryKey: [string, GetAISDataProcessingCheckParams];
  }) => {
    const [_key, { clientId }] = queryKey;
    if (clientId) {
      const url = `/clients/${clientId}/qbo/import/ais-data/processing-check/`;
      return this.fetchData(url, aisDataProcessingCheckSchema);
    }
    console.warn(
      "clientId is not provided, AISProfileServices.getAISDataProcessingCheck"
    );
    return null;
  };

  disconnectQBOAccount = async ({ clientId }: DisconnectQBOAccountParams) => {
    if (clientId) {
      return tggClient.post<
        never,
        z.infer<typeof disconnectQBOAccountStatusSchema>
      >(`/qbo/clients/${clientId}/disconnect`);
    }
    console.warn(
      "clientId is not provided, AISProfileServices.disconnectQBOAccount"
    );
    return null;
  };

  getDisconnectQBOAccountStatus = async ({
    queryKey,
  }: {
    queryKey: [string, DisconnectQBOAccountParams];
  }) => {
    const [_key, { clientId }] = queryKey;
    if (clientId) {
      return tggClient.get<
        never,
        z.infer<typeof disconnectQBOAccountStatusSchema>
      >(`/qbo/clients/${clientId}/disconnect`);
    }
    console.warn(
      "clientId is not provided, AISProfileServices.getDisconnectQBOAccountStatus"
    );
    return null;
  };
}

const instance = new AISProfileServices();

export default instance;
