import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import AuthServices from "@services/AuthServices";
import { ClientsApi } from "@tgg_accounting/tenant-api";
import { StatusFilterEnum } from "@utils/types/global";
import { RootState } from "store/store";

export type Client = {
  id: string;
  name: string;
  timeZoneId: string;
  currencyCode: null;
  locale: null;
  type: string;
  urlSlug: null;
  businessSummary?: string;
  tagLine?: string;
  website?: string;
  logoUrl?: string;
  address?: {
    city: string;
    state: null;
    postalCode?: string;
    addressLine1: string;
    addressLine2: string;
  };
  phoneNumber?: string;
  activeInfo: {
    active: boolean;
    activeStatusChangedAt?: string;
  };
  auditInfo: {
    createdAt: string;
    createdBy?: string;
    updatedAt: string;
    updatedBy?: string;
  };
  archiveInfo: {
    archived: boolean;
    archivedStatusChangedAt: null;
  };
  longTermGoals: null;
  themeColor: null;
  domainName: null;
  permissions: Array<string>;
};

const getApi = () => {
  const accessToken = AuthServices.getAccessToken();
  return new ClientsApi(
    {
      basePath: process.env.NEXT_PUBLIC_TGG_TENANT_API_BASE_URI,
      baseOptions: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    },
    process.env.NEXT_PUBLIC_TGG_TENANT_API_BASE_URI
  );
};

export const fetchClients = createAsyncThunk(
  "clients/fetchClients",
  async (params: { agencyId: string }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const api = getApi();
    const response = await api.clientsControllerGetPermissions(
      "advanced",
      params.agencyId,
      undefined,
      state.clients.searchParams.sort.direction,
      state.clients.searchParams.sort.by,
      12,
      state.clients.searchParams.skip,
      state.clients.searchParams.term,
      {
        [StatusFilterEnum.ACTIVE]: "true",
        [StatusFilterEnum.DEACTIVATED]: "false",
        [StatusFilterEnum.ALL]: "",
      }[state.clients.searchParams.status],
      undefined
    );
    return response.data as {
      items: Client[];
      recordsPerPage: number;
      totalPages: number;
      totalRecords: number;
      currentPageNumber: number;
    };
  }
);

const TGG_CLIENT_VIEW_TYPE = "TGG_CLIENT_VIEW_TYPE";

const initialState = () => {
  let initialView = "GRID";
  if (typeof window !== "undefined") {
    const savedViewType = localStorage.getItem(TGG_CLIENT_VIEW_TYPE) || "GRID";
    if (["GRID", "LIST"].includes(savedViewType)) {
      initialView = savedViewType as "GRID" | "LIST";
    }
  }
  return {
    clients: [] as Client[],
    isLoading: true,
    totalPages: 0,
    currentPageNumber: 0,
    searchParams: {
      view: initialView as "GRID" | "LIST",
      term: "",
      status: StatusFilterEnum.ACTIVE,
      sort: {
        by: "name",
        direction: "ASC" as "ASC" | "DESC",
      },
      skip: 0,
    },
  };
};
type State = ReturnType<typeof initialState>;

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setView(state, action: PayloadAction<"GRID" | "LIST">) {
      state.searchParams.view = action.payload;
      localStorage.setItem(TGG_CLIENT_VIEW_TYPE, action.payload);
    },
    setSearchParams(
      state,
      action: PayloadAction<Partial<State["searchParams"]>>
    ) {
      state.searchParams = {
        ...state.searchParams,
        ...action.payload,
        sort: {
          ...state.searchParams.sort,
          ...action.payload.sort,
        },
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchClients.fulfilled, (state, action) => {
      state.clients = action.payload.items;
      state.isLoading = false;
      state.totalPages = action.payload.totalPages;
      state.currentPageNumber = action.payload.currentPageNumber;
    });
    builder.addCase(fetchClients.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchClients.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const clientsPageActions = clientsSlice.actions;
