import { localKeys } from "@utils/constant";

interface Data {
  isAccountRoleAssumed: boolean;
  accountId: string;
  clientId: string;
  isRoleAssumed: boolean;
  path: string;
  asPath: string;
}

class LocalServices {
  setQBOSession = (type: string, data: Data) => {
    switch (type) {
      case "get": {
        const value = localStorage.getItem(localKeys.QBOSession);
        if (value) {
          return JSON.parse(window.atob(value));
        }
        return null;
      }
      case "set":
        return localStorage.setItem(
          localKeys.QBOSession,
          window.btoa(JSON.stringify(data))
        );

      case "remove":
        localStorage.removeItem(localKeys.QBOSession);
        break;
      default:
        break;
    }
  };

  setFullReportAPICallsStarted = (type: string, data: Data) => {
    switch (type) {
      case "get": {
        const value = sessionStorage.getItem(
          localKeys.fullReportAPICallsStarted
        );
        if (value) {
          return JSON.parse(value);
        }
        return [];
      }
      case "set":
        return sessionStorage.setItem(
          localKeys.fullReportAPICallsStarted,
          JSON.stringify(data)
        );

      case "remove":
        sessionStorage.removeItem(localKeys.fullReportAPICallsStarted);
        break;
      default:
        break;
    }
  };

  setFullReportAPICallsDone = (type: string, data: Data) => {
    switch (type) {
      case "get": {
        const value = sessionStorage.getItem(localKeys.fullReportAPICallsDone);
        if (value) {
          return JSON.parse(value);
        }
        return [];
      }
      case "set":
        return sessionStorage.setItem(
          localKeys.fullReportAPICallsDone,
          JSON.stringify(data)
        );

      case "remove":
        sessionStorage.removeItem(localKeys.fullReportAPICallsDone);
        break;
      default:
        break;
    }
  };
}

const instance = new LocalServices();

export default instance;
