import { ReportsApi } from "@tgg_accounting/pdf-engine-api/dist/apis/reports-api";

import axiosHttpClient, { getAccessToken } from "./helperFunction";

export const tggClient = axiosHttpClient(
  process.env.NEXT_PUBLIC_TGG_API_BASE_URI,
  "tgg"
);

export const defaulTggClient = axiosHttpClient(
  process.env.NEXT_PUBLIC_TGG_API_BASE_URI,
  "tgg",
  false
);

export const tggPDFClient = new ReportsApi({
  basePath: process.env.NEXT_PUBLIC_TGG_PDF_API_BASE_URI,
  apiKey: getAccessToken,
});

export const tggTenantClient = axiosHttpClient(
  process.env.NEXT_PUBLIC_TGG_TENANT_API_BASE_URI,
  "tggTenant"
);
