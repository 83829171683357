import { useAuth } from "@context/auth";
import AISProfileServices from "@services/AISAPIs/AISProfile";
import { useQuery } from "react-query";

export function useClientAISProfile(clientId: string) {
  const { userInfo, isAuthenticated } = useAuth();
  const isClientUser = userInfo?.isClientUser;

  const {
    data: aisProfileDetails,
    isSuccess: isAisProfileDetailsFetched,
    refetch,
  } = useQuery(
    [
      `/get/clients/${clientId}/ais-profile`,
      {
        clientId: clientId,
      },
    ],
    AISProfileServices.getAISProfile,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!isAuthenticated && !!clientId && !isClientUser,
    }
  );
  return {
    aisProfileDetails: isAisProfileDetailsFetched ? aisProfileDetails : null,
    refetchAISProfileDetails: refetch,
  };
}
