import { financialYearIds, months } from "@utils/constant";
import { formatPhoneNumber } from "@utils/functions/common";
import {
  isEmpty,
  isEmptyObject,
  isObjectsEqual,
} from "@utils/functions/validation";
import { Address } from "@utils/types/global";
import Immutable from "immutable";
import diff from "immutablediff";
import moment from "moment";

export function addressHelper(
  address: Address,
  isShort = false,
  isReturnNull = false
) {
  if (!isEmptyObject(address)) {
    const shortAddress = `${address?.city || ""}, ${
      address?.state?.name || ""
    }`;
    if (isShort) {
      return shortAddress;
    }
    return `${address?.addressLine1 || ""},${
      address?.addressLine2 ? ` ${address?.addressLine2},` : ""
    } ${shortAddress} ${address?.postalCode || ""}`;
  }
  if (isReturnNull) {
    return null;
  }
  return "-";
}

function clientFinancialResponseHelper(profile) {
  const isCalendar = profile?.financialYear === financialYearIds.calendar;
  const isSpecificMonth =
    profile?.financialYear === financialYearIds.specificMonth;
  const startingMonth = isSpecificMonth
    ? months.find((m) => m.title === profile?.calendar)?.value
    : 0;
  return {
    entityType: profile?.entity?.name || "-",
    taxBasis: profile?.taxBasisType?.name || "-",
    stateOfIncorporation: profile?.state?.name || "-",
    isCalendar: isCalendar,
    isSpecificMonth: isSpecificMonth,
    specificMonth: isSpecificMonth ? profile?.calendar : "",
    financialYear: !isEmptyObject(profile)
      ? isSpecificMonth
        ? `Fiscal (${moment(`${startingMonth}`, "M").format("MMMM")} to
        ${moment(`${startingMonth}`, "M").add(11, "M").format("MMMM")})`
        : "Calendar"
      : "-",
  };
}

export function clientAdvisorResponseHelper(items) {
  return items.map((item) => ({
    advisorTypeCode: item?.advisorType?.code || "",
    advisorTypeName: item?.advisorType?.name || "",
    name: `${item?.firstName} ${item?.lastName || ""} ${
      item?.institutionName ? `(${item?.institutionName})` : ""
    }`,
    institutionName: item?.institutionName || "",
  }));
}

export function clientExecutiveResponseHelper(items) {
  return items.map((item) => ({
    executiveCode: item?.executiveType?.code || "",
    executiveName: item?.executiveType?.name || "",
    name: `${item?.firstName} ${item?.lastName || ""}`,
  }));
}

// Client List Item Response Helper
export function clientListItemResponseHelper(item) {
  return {
    id: item?.id || "",
    name: item?.name || "",
    logoUrl: item?.logoUrl || "",
    address: addressHelper(item?.address),
    shortAddress: addressHelper(item?.address, true, true),
    website: item?.website || "-",
    phoneNumber: formatPhoneNumber(item?.phoneNumber),
    tagLine: item?.tagLine || "-",
    businessSummary: item?.businessSummary || "-",
    isActive: !!item?.activeInfo?.active,
    agencyId: item?.agency?.id || "",
  };
}

// Assigned Client Item Response Helper
export function assignedClientResponseHelper(item) {
  return {
    id: item?.id || "",
    name: item?.name || "",
    logoUrl: item?.logoUrl || "",
    shortAddress: addressHelper(item?.address, true, true),
    isActive: !!item?.activeInfo?.active,
    jobFunction: item?.jobFunction || "",
  };
}

// Client Detail Response Helper
export function clientItemResponseHelper(item) {
  const financialProfile = clientFinancialResponseHelper(
    item?.financialProfile
  );
  return {
    id: item?.id || "",
    name: item?.name || "",
    logoUrl: item?.logoUrl || "",
    address: addressHelper(item?.address),
    website: item?.website || "-",
    haveWebsite: !isEmpty(item?.website || ""),
    phoneNumber: formatPhoneNumber(item?.phoneNumber),
    havePhoneNumber: !isEmpty(item?.phoneNumber || ""),
    tagLine: item?.tagLine || "",
    businessSummary: item?.businessSummary || "-",
    ...financialProfile,
    isActive: !!item?.activeInfo?.active,
  };
}

export function clientExecutiveMemberAddEditHelper(oldObj, newObj) {
  const newData = Immutable.Map({
    firstName: newObj.firstName,
    lastName: newObj.lastName,
    emailAddress: newObj.email ? newObj.email : null,
    executiveTypeId: newObj.executiveRole.id,
  });
  const oldData = Immutable.Map({
    firstName: oldObj?.firstName,
    lastName: oldObj?.lastName,
    emailAddress: oldObj?.emailAddress,
    executiveTypeId: oldObj?.executiveType?.id,
  });
  return diff(oldData, newData);
}

export function clientAdvisorMemberAddEditHelper(oldObj, newObj) {
  const newData = Immutable.Map({
    firstName: newObj.firstName,
    lastName: newObj.lastName,
    emailAddress: newObj.email ? newObj.email : null,
    institutionName: newObj.institutionName,
    advisorTypeId: newObj.advisorRole.id,
  });
  const oldData = Immutable.Map({
    institutionName: oldObj?.institutionName || "",
    firstName: oldObj?.firstName || "",
    lastName: oldObj?.lastName || "",
    emailAddress: oldObj?.emailAddress || "",
    advisorTypeId: oldObj?.advisorType?.id,
  });
  return diff(oldData, newData);
}

export function getFinancialRequestData(obj) {
  return {
    entityTypeId: obj.entityType?.id,
    taxBasisTypeId: obj?.taxBasis?.id,
    stateId: obj?.state?.id,
    financialYear: obj?.financialYear,
    calendar:
      obj?.financialYear === financialYearIds.specificMonth
        ? obj?.calendar?.title
        : "",
  };
}

export function isFinancialDataChanged(oldObj, newObj) {
  const oldData = {
    entityType: oldObj?.financialProfile?.entity?.id || "",
    taxBasis: oldObj?.financialProfile?.taxBasisType?.id || "",
    state: oldObj?.financialProfile?.state?.id || "",
    financialYear:
      oldObj?.financialProfile?.financialYear || financialYearIds.calendar,
    calendar: oldObj?.financialProfile?.calendar || "",
  };

  const newData = {
    entityType: newObj.entityType?.id,
    taxBasis: newObj?.taxBasis?.id,
    state: newObj?.state?.id,
    financialYear: newObj?.financialYear,
    calendar:
      newObj?.financialYear === financialYearIds.specificMonth
        ? newObj?.calendar?.title
        : "",
  };

  return isObjectsEqual(oldData, newData);
}

export function statusFilterHelper(filter: {
  id: string;
  label: string;
}): boolean | undefined {
  switch (filter?.id) {
    case "all":
      return undefined;
    case "true":
      return true;
    case "false":
      return false;
    default:
      return undefined;
  }
}
