import AuthServices from "@services/AuthServices";
import axios from "axios";
import { toast } from "react-toastify";

function responseHelper(response) {
  if (
    response?.status === 200 ||
    response?.status === 201 ||
    response?.status === 204
  ) {
    return response.data;
  }
  return null;
}

function errorHelper(error) {
  if (error?.response?.data) {
    const message = error.response.data;
    if (message?.code) {
      if (
        message.code === "ERROR_QBO_CONNECTION" ||
        message.code === "IMPORT_ALREADY_IN_PROCESS"
      ) {
        return message.code;
      }
      toast.error(message.message);
      return null;
    }
  }
  return null;
}

export function errorHelperAIS(error) {
  if (error?.code) {
    if (error?.code === "No AISProfile matches the given query.") {
      return "NOT_FOUND";
    }
    if (error.code === "QUICKBOOKS_INTEGRATION_ERROR") {
      return "QUICKBOOKS_INTEGRATION_ERROR";
    }
    toast.error(error.message);
    return null;
  }

  return null;
}

export function getAccessToken() {
  const accessToken = AuthServices.getAccessToken();
  if (accessToken !== null) {
    return `Bearer ${accessToken}`;
  }
  return "";
}

export function getAgencyId() {
  let agencyId = "";
  const urlString = window.location.href;
  const url = new URL(urlString);
  agencyId = url.searchParams.get("aId") || "";
  return agencyId;
}

export default function axiosHttpClient(
  baseURL: string | undefined,
  client: string,
  useCustomErrorHandler = true
) {
  let headers = {
    "Content-Type": "application/json",
  };

  const axiosClient = axios.create({
    baseURL,
    headers,
  });

  axiosClient.interceptors.request.use(
    function configHeader(config) {
      config.headers = {
        ...config.headers,
        Authorization: getAccessToken(),
      };
      if (
        baseURL !== process.env.NEXT_PUBLIC_TGG_AIS_API_BASE_URI &&
        baseURL !== process.env.NEXT_PUBLIC_TGG_TENANT_API_BASE_URI
      ) {
        const agencyId = getAgencyId();
        if (agencyId) {
          config.headers = { ...config.headers };
        }
      }
      return config;
    },
    function configError(error) {
      return Promise.reject(error);
    }
  );

  switch (client) {
    case "tgg": {
      axiosClient.interceptors.response.use(
        responseHelper,
        useCustomErrorHandler ? errorHelper : undefined
      );
      break;
    }
    case "tggAIS": {
      axiosClient.interceptors.response.use(responseHelper, errorHelperAIS);
      break;
    }
    case "tggPDF": {
      axiosClient.interceptors.response.use(responseHelper, errorHelper);
      break;
    }
    case "tggTenant": {
      axiosClient.interceptors.response.use(responseHelper, errorHelper);
      break;
    }
    default:
      axiosClient.interceptors.response.use(responseHelper, errorHelper);
      break;
  }
  return axiosClient;
}
