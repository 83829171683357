import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  addingHubAiCommentary: false,
  selectedNotes: {} as Record<string, boolean>,
};

export const applyNotesAi = createAsyncThunk(
  "configureNotes/applyNotesAi",
  async (_payload: { notes: string[] }) => {
    try {
      toast.success("AI commentary applied to notes");
    } catch (error) {
      toast.error("Error applying AI commentary to notes");
      throw error;
    }
  }
);

export const configureNotesSlice = createSlice({
  name: "configureNotes",
  initialState,
  reducers: {
    toggleNotesAiCommentary(state) {
      state.addingHubAiCommentary = !state.addingHubAiCommentary;
      if (!state.addingHubAiCommentary) {
        state.selectedNotes = {};
      }
    },
    toggleNoteSelection(state, action: PayloadAction<string>) {
      if (state.selectedNotes[action.payload]) {
        delete state.selectedNotes[action.payload];
      } else {
        state.selectedNotes[action.payload] = true;
      }
    },
    selectAllNotes(state, action: PayloadAction<string[]>) {
      action.payload.forEach((note) => {
        state.selectedNotes[note] = true;
      });
    },
    clearAllSelectedNotes(state) {
      state.selectedNotes = {};
    },
    setSelectedNotes(state, action: PayloadAction<string[]>) {
      state.selectedNotes = action.payload.reduce((acc, note) => {
        acc[note] = true;
        return acc;
      }, {} as Record<string, boolean>);
    },
  },
  extraReducers(builder) {
    builder.addCase(applyNotesAi.fulfilled, (state) => {
      state.addingHubAiCommentary = false;
      state.selectedNotes = {};
    });
  },
});

export const {
  toggleNotesAiCommentary,
  toggleNoteSelection,
  selectAllNotes,
  clearAllSelectedNotes,
  setSelectedNotes,
} = configureNotesSlice.actions;
