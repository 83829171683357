var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"b64ba9a2750453896a440011d0216f97031cfb36"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

if (
  process.env.NEXT_PUBLIC_DEBUG_SENTRY ||
  process.env.NODE_ENV !== "development"
) {
  Sentry.init({
    dsn: "https://b3fd0010127b63b0f4e489ac63f8fa8d@o4506344193261568.ingest.sentry.io/4506354549260288",

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.4,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: !!process.env.NEXT_PUBLIC_DEBUG_SENTRY,

    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
      Sentry.replayIntegration({
        // Additional Replay configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
  });
}
