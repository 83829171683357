const newUserRights = {
  client: {
    create: "clients.create",
    edit: "clients.edit",
    view: "clients.view",
    admin: "clients.all",
  },
  tggUser: {
    create: "tgg.users.create",
    edit: "tgg.users.edit",
    view: "tgg.users.view",
  },
  clientUser: {
    create: "client.users.create",
    view: "client.users.view",
    manage: "client.users.manage",
    admin: "client.users.all",
  },
  kpi: {
    create: "kpi.create",
    view: "kpi.view",
    archive: "kpi.archive",
  },
  dataset: {
    create: "dataset.create",
    view: "dataset.view",
    edit: "dataset.edit",
  },
  pr: {
    view: "pr.view",
    create: "pr.create",
    edit: "pr.edit",
    archive: "pr.archive",
  },
  cg: {
    create: "cg.create",
    lock: "cg.lock",
    archive: "cg.archive",
    view: "cg.view",
  },
  accounts: {
    view_coa: "accounts.coa.view",
    edit_coa: "accounts.coa.edit",
    view_ais: "account.aisprofile.view",
    sync_ais: "account.ais.sync",
    map_ais: "account.ais.map",
    manage: "account.isclassification.manage",
  },
  reports: {
    view: "reports.package.view",
    manage: "reports.package.manage",
    delete: "reports.package.delete",
    task_view: "reports.task.view",
    publish: "reports.publish",
  },
  templates: {
    view: "reports.templates.view",
    edit: "reports.templates.edit",
    create: "reports.templates.create",
  },
};

export const roleGroupCodes = {
  owner: "owner",
  fullAccess: "full-access",
  standardAccess: "standard-access",
  readOnly: "read-only",
};

export function checkUserRights(permissionType, isAuthenticated, userInfo) {
  if (!isAuthenticated) return false;
  const { isSuperAdmin, isActive, permissions } = userInfo;
  if (!isActive) return false;
  if (isSuperAdmin) return true;
  const permissionKey = Object.keys(newUserRights).find((ur) =>
    permissionType.includes(ur)
  );
  if (permissions.some((p) => p.includes(`${permissionKey}.admin`))) {
    return true;
  }
  if (permissions.includes(permissionType)) {
    return true;
  }
  return false;
}
