import { useAuth } from "@context/auth";
import { useClient } from "@context/client";
import AgencyServices from "@services/APIs/Agency";
import { ClientDisplayModel } from "@tgg_accounting/tenant-api";
import { getQueryString } from "@utils/functions/common";
import { routes, uniqQueryStrings } from "@utils/route";
import { useRouter } from "next/router";
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQuery } from "react-query";

const AccountContext = createContext({
  isRoleAssumed: false,
  accountId: "",
  accountDetails: null as ClientDisplayModel | null,
  assumeRole: (_id: string, _isRedirect: boolean) => {},
  removeRole: () => {},
  refetchAccountDetails: () => {},
});

export const useAccount = () => useContext(AccountContext);

const AccountProvider: FC<PropsWithChildren> = ({ children }) => {
  const route = useRouter();
  const { userInfo } = useAuth();
  const {
    isClientUser,
    isSuperAdmin,
    isTGGUser,
    isGlobalUser,
    isMultiClient,
    agency,
  } = userInfo ?? {};

  const { isRoleAssumed: isClientRoleAssumed, removeRole: removeClientRole } =
    useClient();
  const assumedAccountId = route.query[uniqQueryStrings.accountId];
  const routeAccountId = route.query.accountId;
  const [isRoleAssumed, setIsRoleAssumed] = useState<boolean>(false);
  const [accountId, setAccountId] = useState<string>("");
  const [accountDetails, setAccountDetails] =
    useState<ClientDisplayModel | null>(null);

  const {
    data: client,
    isSuccess,
    isLoading,
    refetch: refetchAccountDetails,
  } = useQuery(
    [
      `/agencies/${accountId}`,
      {
        agencyId: accountId,
        include: ["agency"],
      },
    ],
    AgencyServices.getAgencyById,
    {
      refetchOnWindowFocus: false,
      enabled: !!accountId && !isClientUser && !isTGGUser,
    }
  );

  useEffect(() => {
    if (accountId !== accountDetails?.id) {
      setAccountDetails(null);
    }
  }, [accountId, accountDetails]);

  useEffect(() => {
    if (client && isSuccess) {
      setAccountDetails(client);
    }
  }, [isLoading, client]);

  useEffect(() => {
    if (agency && (isTGGUser || isClientUser)) {
      setAccountDetails(agency);
    }
  }, [agency, accountId]);

  const assumeRole = useCallback(
    (id: string, isRedirect: boolean) => {
      setAccountId(id);
      document.cookie = `hub.agency.id=${id}`;
      if (isRedirect) {
        const qs = getQueryString({
          [uniqQueryStrings.accountId]: id,
        });
        route.push({
          pathname: routes.admin.client.list,
          query: qs,
        });
      }
    },
    [route, setAccountId]
  );

  const removeRole = useCallback(() => {
    if (isClientRoleAssumed) {
      removeClientRole();
    }
    route.push(routes.admin.agency.list);
  }, [route, removeClientRole, isClientRoleAssumed]);

  useEffect(() => {
    if (
      isSuperAdmin ||
      isTGGUser ||
      isGlobalUser ||
      (isClientUser && isMultiClient)
    ) {
      if (assumedAccountId || routeAccountId) {
        setAccountId(
          assumedAccountId?.toString() || routeAccountId?.toString() || ""
        );
        document.cookie = `hub.agency.id=${
          assumedAccountId?.toString() || routeAccountId?.toString()
        }`;
      } else {
        setAccountId("");
        document.cookie = `hub.agency.id=`;
      }
      if (isRoleAssumed) {
        if (!assumedAccountId || isClientUser) {
          setIsRoleAssumed(false);
        }
      } else {
        if (assumedAccountId && !isClientUser) {
          setIsRoleAssumed(true);
        }
      }
    }
    if (userInfo?.isClientUser && !userInfo?.isMultiClient) {
      setAccountId(userInfo?.parentId);
      document.cookie = `hub.agency.id=${userInfo.parentId}`;
    }
  }, [assumedAccountId, routeAccountId, isRoleAssumed, userInfo]);

  return (
    <AccountContext.Provider
      value={{
        isRoleAssumed,
        accountId,
        assumeRole,
        removeRole,
        accountDetails,
        refetchAccountDetails,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export default AccountProvider;
