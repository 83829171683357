import { useQuery } from "react-query";
import ReportsServices from "@services/APIs/Reports";

export function useFinancialPackageDetails(clientId, financialPackageId) {
  const {
    isFetched,
    data: financialPackage,
    refetch,
  } = useQuery(
    [
      `/get/clients/${clientId}/financial-package/${financialPackageId}`,
      {
        clientId,
        financialPackageId,
      },
    ],
    ReportsServices.getFinancialPackageDetails,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!clientId && !!financialPackageId,
    }
  );

  return !isFetched
    ? { financialPackage: null, refetch: () => {} }
    : { financialPackage, refetch };
}
