export const staticIndex = [
  { title: "Business Overview & Executive Summary", page: "2 - 4" },
  { title: "Key Performance Indicators", page: "5 - 6" },
  { title: "Balance Sheet", page: "7" },
  { title: "Triple Bottom Line", page: "8" },
  { title: "Statement of Cash Flows", page: "10" },
  { title: "Notes to the Financial Statements", page: "11 - 13" },
  { title: "Appendix A: Gross Profit by Location – California", page: "14" },
  { title: "Appendix B: Gross Profit by Location – Colorado", page: "15" },
  { title: "Appendix C: Operating Expenses – Expanded", page: "16" },
  { title: "Appendix D: Income Statement - Actual vs. Budget", page: "17" },
];

// Report Component Response Helper
export function reportComponentItemResponseHelper(item) {
  return {
    id: item?.id || "",
    name: item?.name || "",
    isConfigurable: item?.configurable || false,
    description: item?.description || "",
    displayOrder: item?.displayOrder,
    isDefault: item?.isDefault || false,
    isAdded: !!item?.configuration,
    isPreviewDisabled: item?.isPreviewDisabled || false,
    configuration: item?.configuration || null,
    haveChildren: item?.childs?.length > 0,
    children: item?.childs || [],
  };
}

export function reportComponentChildItemResponseHelper(item) {
  return {
    id: item?.id || "",
    name: item?.name || "",
    code: item?.code || "",
  };
}
