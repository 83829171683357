import { tggClient, tggTenantClient } from "@services/AxiosConfig";

interface ActiveInfo {
  active: boolean;
  activeStatusChangedAt: string | null;
}

interface AuditInfo {
  createdAt: string;
  createdBy: string | null;
  updatedAt: string;
  updatedBy: string | null;
}

interface EntityType {
  id: string;
  name: string;
  description: string | null;
  code: string;
  activeInfo: ActiveInfo;
  auditInfo: AuditInfo;
}

interface State {
  id: string;
  name: string;
  countryCode: string;
  code: string;
}

interface Timezone {
  id: string;
  displayName: string;
}

interface TaxType {
  id: string;
  name: string;
  description: string | null;
  code: string;
  activeInfo: ActiveInfo;
  auditInfo: AuditInfo;
}

interface ReportType {
  id: string;
  name: string;
  description: string;
  code: string;
  activeInfo: ActiveInfo;
  auditInfo: AuditInfo;
}

interface RoleType {
  id: string;
  name: string;
  description: string | null;
  code: string;
  activeInfo: ActiveInfo;
  auditInfo: AuditInfo;
}

interface DatasetFieldType {
  id: string;
  name: string;
  code: string;
  description: string;
  activeInfo: ActiveInfo;
  auditInfo: AuditInfo;
}

interface TripleBottomLineType {
  id: string;
  name: string;
  code: string;
  description: string;
  activeInfo: ActiveInfo;
  auditInfo: AuditInfo;
}

interface ChartType {
  id: string;
  name: string;
  code: string;
  description: string;
  activeInfo: ActiveInfo;
  auditInfo: AuditInfo;
}

interface RoleGroup {
  id: string;
  name: string;
  description: string | null;
  code: string;
  activeInfo: ActiveInfo;
  auditInfo: AuditInfo;
}

interface GetTripleBottomLineTypesInput {
  queryKey: [string, { clientId: string; year: string; month: string }];
}

class SeedDataServices {
  getEntityTypes = async (): Promise<EntityType> => {
    return await tggTenantClient.get("/type-data/entity-types");
  };

  getStates = async (): Promise<State> => {
    return await tggTenantClient.get("/type-data/states");
  };

  getTimezones = async (): Promise<Timezone> => {
    return await tggTenantClient.get("/type-data/time-zones");
  };

  getTaxTypes = async (): Promise<TaxType> => {
    return await tggTenantClient.get("/type-data/tax-types");
  };

  getReportTypes = async (): Promise<ReportType> => {
    return await tggClient.get("/type-data/report-types");
  };

  getExecutiveRoleTypes = async (): Promise<RoleType> => {
    return await tggTenantClient.get("/type-data/executive-role-types");
  };

  getAccountingRoleTypes = async (): Promise<RoleType> => {
    return await tggTenantClient.get("/type-data/accounting-role-types");
  };

  getAdvisorRoleTypes = async (): Promise<RoleType> => {
    return await tggTenantClient.get("/type-data/advisor-types");
  };

  getDataSetFieldTypes = async (): Promise<DatasetFieldType> => {
    return await tggClient.get("/type-data/dataset-field-types");
  };

  getTripleBottomLineTypes = async (
    input: GetTripleBottomLineTypesInput
  ): Promise<TripleBottomLineType> => {
    const [_, { clientId = "", year = "", month = "" }] = input.queryKey;
    return await tggClient.get(`/type-data/tbl-chart-types`, {
      params: { clientId, year, month },
    });
  };

  getChartTypes = async (): Promise<ChartType> => {
    return await tggClient.get(`/type-data/chart-types`);
  };

  getRoleGroups = async (): Promise<RoleGroup> => {
    return await tggTenantClient.get(`/type-data/role-groups`);
  };
}

const instance = new SeedDataServices();

export default instance;
