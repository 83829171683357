export interface Address {
  city: string;
  state: State;
  postalCode: string;
  addressLine1: string;
  addressLine2: string;
}

export interface State {
  id: string;
  code: string;
  name: string;
  countryCode: string;
}

export interface ActiveInfo {
  active: boolean;
  activeStatusChangedAt: Date | null;
}

export interface ArchiveInfo {
  archived: boolean;
  archivedStatusChangedAt: Date | null;
}

export interface AuditInfo {
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface SortState {
  sortDir: string;
  sortBy: string;
}

export interface StatusFilter {
  id: string;
  label: string;
}

export enum StatusFilterEnum {
  ALL = "All",
  ACTIVE = "Active",
  DEACTIVATED = "Deactivated",
}

export const STATUS_FILTER_OPTIONS = [
  {
    id: "all",
    label: StatusFilterEnum.ALL,
  },
  {
    id: "true",
    label: StatusFilterEnum.ACTIVE,
  },
  {
    id: "false",
    label: StatusFilterEnum.DEACTIVATED,
  },
];
