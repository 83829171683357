import { useEffect, useState } from "react";

function useLocalState<T>(initialState: T, stateKey: string) {
  if (!stateKey) {
    console.error("Localstorage Key is not passed. useLocalState");
  }
  const [state, setState] = useState<T>(initialState);
  const [initValueLoaded, setInitValueLoaded] = useState<boolean>(false);

  useEffect(() => {
    const localValue = localStorage.getItem(stateKey);
    if (localValue) {
      setState(JSON.parse(localValue));
    } else {
      localStorage.setItem(stateKey, JSON.stringify(initialState));
    }
    setInitValueLoaded(true);
  }, [initialState, stateKey]);

  useEffect(() => {
    if (initValueLoaded) {
      localStorage.setItem(stateKey, JSON.stringify(state));
    }
  }, [state, initValueLoaded, stateKey]);

  return [state, setState] as const;
}

export default useLocalState;
