import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { tggTenantClient } from "@services/AxiosConfig";
import { AgencyDisplayModel } from "@tgg_accounting/tenant-api";
import { RootState } from "store/store";

export enum UserStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber?: string;
  institutionName: null;
  imageUrl: null;
  subjectId?: string;
  userStatus: string;
  activeInfo: {
    active: boolean;
    activeStatusChangedAt?: string;
  };
  auditInfo: {
    createdAt: string;
    createdBy?: string;
    updatedAt: string;
    updatedBy?: string;
  };
  archiveInfo: {
    archived: boolean;
    archivedStatusChangedAt: null;
  };
  lockInfo: {
    locked: boolean;
    lockedStatusChangedAt?: string;
  };
  agency: AgencyDisplayModel;
  invitation: {
    id: string;
    invitationCode: string;
    invitationStatus: string;
    activeInfo: {
      active: boolean;
      activeStatusChangedAt: null;
    };
    auditInfo: {
      createdAt: string;
      createdBy?: string;
      updatedAt: string;
      updatedBy?: string;
    };
    lastResentAt: string;
  };
  permissions: Array<string>;
  userRoles: Array<string>;
  client: null;
  roleGroup?: {
    id: string;
    roleName: string;
    code: string;
    description: string;
    isGlobal: boolean;
    globalName: string;
  };
};

export const fetchHubUsers = createAsyncThunk(
  "hubUsers/fetchUsers",
  async (payload: { agencyId: string }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const users = (await tggTenantClient.get("/users", {
      params: {
        agencyId: payload.agencyId,
        include: "invitation",
        userRole: "tgg-user",
        sortDir: state.hubUsers.queryFilters.sort.direction,
        sortBy: state.hubUsers.queryFilters.sort.by,
        take: 10,
        skip: state.hubUsers.queryFilters.skip,
        searchTerm: state.hubUsers.queryFilters.searchTerm,
        clientId: "",
        active: state.hubUsers.queryFilters.status === UserStatus.ACTIVE,
      },
    })) as { items: User[]; totalPages: number; currentPageNumber: number };
    return users;
  }
);

const initialState = {
  users: [] as User[],
  isLoading: false,
  queryFilters: {
    searchTerm: "",
    skip: 0,
    status: UserStatus.ACTIVE,
    sort: {
      direction: "ASC" as "ASC" | "DESC",
      by: "firstName",
    },
  },
  pagination: {
    totalPages: 1,
    currentPageNumber: 1,
  },
};

export const hubUsersSlice = createSlice({
  name: "hubUsers",
  initialState,
  reducers: {
    setQueryFilters: (
      state,
      action: PayloadAction<Partial<typeof state.queryFilters>>
    ) => {
      state.queryFilters = {
        ...state.queryFilters,
        ...action.payload,
        sort: {
          ...state.queryFilters.sort,
          ...action.payload.sort,
        },
      };
    },
    setPagination: (
      state,
      action: PayloadAction<Partial<typeof state.pagination>>
    ) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
      state.queryFilters.skip = (state.pagination.currentPageNumber - 1) * 10;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchHubUsers.fulfilled, (state, action) => {
      state.users = action.payload.items;
      state.pagination = {
        totalPages: action.payload.totalPages,
        currentPageNumber: action.payload.currentPageNumber,
      };
      state.isLoading = false;
    });
    builder.addCase(fetchHubUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchHubUsers.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const hubUsersActions = hubUsersSlice.actions;
export default hubUsersSlice.reducer;
