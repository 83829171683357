export const nonAccountingStepIds = {
  createDataSet: "CREATE_DATE_SET",
  previewDataSet: "PREVIEW_DATE_SET",
  configureKPI: "CONFIGURE_KPI",
};

export const nonAccountingKPIsVariables = {
  variable: "VARIABLE",
  list: "LIST",
};

export const nonAccountingOption = [
  {
    title: "kpis.nonAccountingSteps.createDataSet",
    id: nonAccountingStepIds.createDataSet,
  },
  {
    title: "kpis.nonAccountingSteps.buildKPI",
    id: nonAccountingStepIds.configureKPI,
  },
];

export const dataSetVariableProperties = {
  cumulative: "cumulative",
  nonCumulative: "non-cumulative",
};

export const dataSetVariablePropertyOption = [
  {
    title: "Additive",
    value: dataSetVariableProperties.cumulative,
  },
  {
    title: "Non-Additive",
    value: dataSetVariableProperties.nonCumulative,
  },
];
