import AuthServices from "@services/AuthServices";
import { tggClient, tggTenantClient } from "@services/AxiosConfig";
import { ClientDisplayModel, ClientsApi } from "@tgg_accounting/tenant-api";
import { Client } from "@utils/types/client";

class ClientsServices {
  clientsApi = new ClientsApi(
    {
      accessToken: () => AuthServices.getAccessToken() || "",
    },
    process.env.NEXT_PUBLIC_TGG_TENANT_API_BASE_URI
  );
  // ADD CLIENT
  addClient = async (data: Client) => {
    if (data) {
      return await tggClient.post(`/clients`, data);
    }
    console.warn("data not provided, ClientsServices.addClient");
    return null;
  };

  // GET ALL CLIENTS
  getAllClients = async ({
    queryKey,
  }: {
    queryKey: [string, { [key: string]: any }];
  }) => {
    const [
      _key,
      {
        agencyId = "",
        sortDir = "ASC",
        sortBy = "",
        take = 10,
        skip = 0,
        searchTerm = "",
        include = "",
        active = "",
      },
    ] = queryKey;
    return await tggClient.get(`/clients`, {
      params: {
        agencyId,
        sortDir,
        sortBy,
        take,
        skip,
        searchTerm,
        include,
        active: active,
      },
    });
  };

  // GET CLIENT BY ID
  getClientById = async ({
    queryKey,
  }: {
    queryKey: [string, { [key: string]: any }];
  }) => {
    const [_key, { clientId, include = [] }] = queryKey;
    if (clientId) {
      const searchParams = new URLSearchParams();
      for (const param of include) {
        searchParams.append("include", param);
      }

      return tggTenantClient.get(
        `/clients/${clientId}?${searchParams.toString()}`
      ) as Promise<ClientDisplayModel>;
    }
    console.warn("clientId not provided, ClientsServices.getClientById");
    return null;
  };

  // UPDATE CLIENT BY ID
  updateClientById = async (clientId: string, data: any) => {
    if (clientId) {
      return await tggClient.patch(`/clients/${clientId}`, data);
    }
    console.warn("clientId not provided, ClientsServices.updateClientById");
    return null;
  };

  // UPDATE LOGO
  updateClientLogo = async (clientId: string, file: string | Blob) => {
    if (clientId && file) {
      let clientLogo = new FormData();
      clientLogo.append("file", file);
      return await tggClient.post(`/clients/${clientId}/logo`, clientLogo, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    }
    console.warn(
      "clientId or file not provided, ClientsServices.updateClientLogo"
    );
    return null;
  };

  // ADD CLIENT FINANCIAL PROFILE
  addClientFinancialProfile = async (clientId: string, data: any) => {
    if (clientId && data) {
      return await tggClient.post(
        `/clients/${clientId}/financial-profile`,
        data
      );
    }

    console.warn(
      "clientId or data not provided, ClientsServices.addClientFinancialProfile"
    );
    return null;
  };

  // ACTIVATE CLIENT
  activateClient = async (clientId: string) => {
    if (clientId) {
      return await tggClient.post(`/clients/${clientId}/activate`);
    }
    console.warn("clientId not provided, ClientsServices.activateClient");
    return null;
  };

  // DEACTIVATE CLIENT
  deactivateClient = async (clientId: string) => {
    if (clientId) {
      return await tggClient.post(`/clients/${clientId}/deactivate`);
    }
    console.warn("clientId not provided, ClientsServices.deactivateClient");
    return null;
  };

  // ADD CLIENT FILE
  addClientFile = async (clientId: string | undefined, file: Blob) => {
    if (clientId) {
      let clientFile = new FormData();
      clientFile.append("file", file);
      return (await tggTenantClient.post(
        `/clients/${clientId}/file`,
        clientFile,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )) as { success: boolean; path: string };
    }
    console.warn(
      "clientId or file not provided, ClientsServices.addClientFile"
    );
    return null;
  };
}

const instance = new ClientsServices();

export default instance;
