import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum SidebarVersion {
  admin = "ADMIN",
  client = "CLIENT",
  owner = "OWNER",
  user = "USER",
}

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    isExpanded: true,
    version: SidebarVersion.client,
    expandedGroups: {} as Record<string, boolean>,
  },
  reducers: {
    toggleSidebar: (state) => {
      state.isExpanded = !state.isExpanded;
    },
    toggleGroup: (state, action: PayloadAction<string>) => {
      state.expandedGroups[action.payload] =
        !state.expandedGroups[action.payload];
    },
    setSidebarVersion: (state, action: PayloadAction<SidebarVersion>) => {
      state.version = action.payload;
    },
  },
});

export const { toggleSidebar, toggleGroup, setSidebarVersion } =
  sidebarSlice.actions;
