import { EnhancedStore } from "@reduxjs/toolkit";
import React, { type PropsWithChildren } from "react";
import { Provider } from "react-redux";

const StoreProvider: React.FC<PropsWithChildren<{ store: EnhancedStore }>> = (
  props
) => {
  return <Provider store={props.store}>{props.children}</Provider>;
};
export default StoreProvider;
