import { useState } from "react";

const useArrState = <T extends Record<string, unknown>>(
  initialState: Array<T>
) => {
  const [state, setState] = useState(initialState);

  const addItem = (value: T) => {
    setState((prevState) => [...prevState, value]);
  };

  const updateItem = (index: number, value: T) => {
    setState((prevState) => {
      const newState = [...prevState];
      if (newState[index] && Object.keys(newState[index]).length > 0) {
        newState[index] = value;
      }
      return newState;
    });
  };

  const updateItemField = <K extends keyof T>(
    index: number,
    key: K,
    value: T[K]
  ) => {
    setState((prevState) => {
      const newState = [...prevState];
      if (newState[index] && key in newState[index]) {
        newState[index][key] = value;
      }
      return newState;
    });
  };

  const removeItem = (index: number) => {
    setState((prevState) => {
      const newState = [...prevState];
      newState.splice(index, 1);
      return newState;
    });
  };

  return [
    state,
    setState,
    addItem,
    updateItem,
    removeItem,
    updateItemField,
  ] as const;
};

export default useArrState;
